import {
  Flex,
  Text,
  Image,
  Box,
  HStack,
  Link,
  Divider,
  Center,
} from "@chakra-ui/react";

const sharedLinkStyle = {
  opacity: "0.7",
  marginBottom: "0.5rem",
  _hover: {
    opacity: 1,
    textDecoration: "none", // Remove the default underline
    color: "#CEB688", // Thicker underline with green color
  },
};

const Footer = () => {
  return (
    <Box bg="primary.600" color="white" p={4}>
      <Flex
        flexDirection="column" // Stack elements vertically on mobile
        alignItems="center" // Center content horizontally on mobile
        maxWidth="1200px" // Set the maximum width
        marginX="auto"
      >
        <Image
          src="https://allianceventura.com/images/ac/logo-white.png"
          width="8rem" // Adjust logo size for mobile
          marginBottom="1rem"
          alt="Logo"
        />
        <HStack marginBottom="1rem">
          <Link
            as="a"
            href="/terms-of-use"
            _hover={{
              color: "primary.500",
              textDecoration: "none",
            }}
          >
            Terms of use
          </Link>
          <Center height="20px">
            <Divider orientation="vertical" />
          </Center>
          <Link
            as="a"
            href="/privacy-policy"
            _hover={{
              color: "primary.500",
              textDecoration: "none",
            }}
          >
            Privacy Policy
          </Link>
          <Center height="20px">
            <Divider orientation="vertical" />
          </Center>
          <Link
            as="a"
            href="/key-risks"
            _hover={{
              color: "primary.500",
              textDecoration: "none",
            }}
          >
            Key Risks
          </Link>
        </HStack>
        <Text fontSize="sm" textAlign="center" marginBottom="1rem">
          © {new Date().getFullYear()} Alliance Capital. All rights reserved
        </Text>
        <Text opacity="0.7" fontSize="xx-small" textAlign="center">
          Investing involves risks, including loss of income
          principal amount. Past performance is no guarantee
          for future results. Historical returns, expected
          returns or probability projections may reflect
          not actual future performance. Although the data we
          third parties are considered reliable, we cannot
          guarantee the accuracy or completeness of data provided by
          investors or other third parties. Alliance Capital nor
          its affiliates provide tax advice and declarations
          no way that the results described herein will occur
          result in a certain tax consequence. Offers to purchase securities
          sell or solicit offers to buy securities
          only be done through official offering documents that
          contain important information about investment objectives,
          risks, compensation and costs.
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;
