import { Grid, Heading, GridItem, Card, Text } from "@chakra-ui/react";
import Footer from "./components/Footer";
import Map from "./components/Map";
import ContactForm from "./components/ContactForm";
import ReactGA from "react-ga4";
import { useLocalization } from "./context/LocalizationContext";

const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");

ReactGA.send({
  hitType: "pageview",
  page: "/contact",
  title: "Contact us | Alliance Capital",
});

export const Contact = () => {
  const { translations } = useLocalization();

  document.title = `${translations.contact_us_hero_text} | Alliance Capital`;

  return (
    <>
      <Fade>
        <Zoom>
          <main>
            <Map />
          </main>
        </Zoom>
        <br />
        <Heading textAlign="center" size="lg" color="primary.600" margin="1rem">
        {translations.contact_title}
        </Heading>
        <Grid
          maxWidth="1200px"
          mx="auto"
          p={{ base: "1rem", md: "2rem" }}
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          gap={5}
        >
          <GridItem>
            <Card
              p="1rem"
              backgroundColor="gray.200"
              //bgGradient="linear(to-br, #CEB688, #CEB688, #0c1F41)"
              fontWeight="bold"
              transition="transform 0.2s ease" // Add transition for smooth animation
              _hover={{
                transform: "translateY(-5px)", // Move the box 4 pixels up on hover
              }}
            >
              <Text color="gray.600"> {translations.contactform_address}</Text>
              <Heading size="sm" color="primary.600">
                Bayswater 507<br />
                Business Bay, Dubai, UAE
              </Heading>
            </Card>
          </GridItem>
          <GridItem>
            <Card
              p="1rem"
              backgroundColor="gray.200"
              fontWeight="bold"
              transition="transform 0.2s ease" // Add transition for smooth animation
              _hover={{
                transform: "translateY(-5px)", // Move the box 4 pixels up on hover
              }}
            >
              <Text color="gray.600"> {translations.contactform_contactdetails}</Text>
              <Heading size="sm" color="primary.600">
                info@alliancecapital.ae
                <br />
                +971 ...
              </Heading>
            </Card>
          </GridItem>
          {/* <GridItem>
            <Card p="1rem" backgroundColor="primary.500" fontWeight="bold">
              <Text color="primary.600">Volg ons</Text>
              <SocialIcon
                bgColor="#fff"
                fgColor="#0c1F41"
                url="https://instagram.com/alliance.capital"
                style={{ width: "2rem", height: "2rem" }}
              />
            </Card>
          </GridItem> */}
        </Grid>
        <Grid
          maxWidth="1200px"
          mx="auto"
          p={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
          templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }} // Three equal-width columns on medium and larger screens
          gap={5} // Gap between grid items
        >
          <GridItem>
            <Heading size="sm" color="primary.600">
            {translations.contactform_title}
            </Heading>
          </GridItem>
          <GridItem>
            <ContactForm />
          </GridItem>
        </Grid>
        <br />
        <br />
        <Footer />
      </Fade>
    </>
  );
};
