import {
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Text,
  Image,
  Heading,
  Card,
  CardBody,
  Stack,
  Box,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Fade = require("react-reveal/Fade");

interface PropertyCardProps {
  propertyName: string;
  location?: string;
  investering: number;
  rendement: number;
  uitbetaling: number;
  img: string;
  uri?: string;
  blurText?: boolean;
}

function createPermalink(name: string): string {
  const cleanedName = name.replace(/[^\w\s]/g, "").replace(/\s+/g, "-");
  const permalink = cleanedName.toLowerCase();

  return permalink;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
  propertyName,
  location,
  investering,
  rendement,
  uitbetaling,
  img,
  uri,
  blurText,
}) => {
  const formattedInvestering = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    compactDisplay: "short",
  }).format(investering);

  const formattedUitbetaling = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    compactDisplay: "short",
  }).format(uitbetaling);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Fade>
      <Link
        as="a"
        textDecoration="none" // Remove underline
        _hover={{ textDecoration: "none" }}
        //href={`/properties/${createPermalink(propertyName)}`}
      >
        <Card
          maxWidth="100%"
          overflowX="auto"
          transition="transform 0.2s ease" // Add transition for smooth animation
          _hover={{
            transform: "translateY(-5px)", // Move the box 4 pixels up on hover
          }}
          cursor="pointer"
        >
          {imageLoaded ? null : (
            <Skeleton
              enableAnimation={true}
              height="250px"
              borderRadius="10px"
            />
          )}

          {!imageLoaded && (
            <Skeleton
              enableAnimation={true}
              height="250px"
              borderRadius="10px"
            />
          )}
          <Image
            src={img}
            alt={propertyName}
            borderTopRadius="lg"
            height="250px"
            justifyContent="contain"
            loading="lazy"
            onLoad={handleImageLoad}
          />

          <CardBody>
            <Stack mt="6" spacing="4">
              <Heading size="md">
                {propertyName ? (
                  propertyName
                ) : (
                  <Skeleton enableAnimation={true} />
                )}
                {imageLoaded}
              </Heading>
              {location ? (
                <Box
                  background="#cbd4e4"
                  borderRadius="lg"
                  padding="0.5rem"
                  inlineSize="max-content" // Set the width to the content's size
                  minInlineSize="max-content"
                >
                  <Flex align="center">
                    <ReactCountryFlag countryCode="AE" svg />
                    <Text marginLeft="0.5rem" fontSize="small" color="black">
                      {location ? location : "Dubai"}
                    </Text>
                  </Flex>
                </Box>
              ) : (
                <Skeleton enableAnimation={true} />
              )}

              <TableContainer marginLeft="-5">
                <Table variant="simple">
                  <Tbody>
                    <Tr>
                      <Td opacity="0.7" fontSize="small">
                      Investment from
                      </Td>
                      <Td
                        fontWeight="bold"
                        fontSize="small"
                        style={blurText ? { filter: "blur(4px)" } : {}}
                      >
                        {formattedInvestering ? (
                          formattedInvestering
                        ) : (
                          <Skeleton enableAnimation={true} />
                        )}
                      </Td>
                    </Tr>
                    {/* <Tr>
                      <Td opacity="0.7" fontSize="small">
                        Rendement v.a.
                      </Td>
                      <Td fontWeight="bold" fontSize="small">
                        {rendement ? (
                          `${rendement}% per jaar`
                        ) : (
                          <Skeleton enableAnimation={true} />
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td opacity="0.7" fontSize="small">
                        Uitbetaling p/m v.a.
                      </Td>
                      <Td fontWeight="bold" fontSize="small">
                        {formattedUitbetaling ? (
                          formattedUitbetaling
                        ) : (
                          <Skeleton enableAnimation={true} />
                        )}
                      </Td>
                    </Tr> */}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </CardBody>
        </Card>
      </Link>
    </Fade>
  );
};

export default PropertyCard;
