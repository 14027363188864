import {
  Box,
  Button,
  ChakraProvider,
  ColorModeScript,
  CSSReset,
  Divider,
  Flex,
  Text,
  Link,
  extendTheme,
} from "@chakra-ui/react";
import customTheme from "./constants/alliance-capital-theme"; // Import the custom theme
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Investeren } from "./Investeren";
import { About } from "./About";
import { NotFound } from "./NotFound";
import Header from "./components/Header";
import { Contact } from "./Contact";
import { Welkom } from "./Welcome";
import { StartInvestment } from "./StartInvestment";
import { GoldenVisa } from "./GoldenVisa";
import ReactGA from "react-ga4";
import { KeyRisks } from "./KeyRisks";
import { TermsOfUse } from "./TermsOfUse";
import { PrivacyPolicy } from "./PrivacyPolicy";
import CookieConsent from "react-cookie-consent";
import { FaCookie } from "react-icons/fa";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "./context/LocalizationContext";
import { Testimonials } from "./Testimonials";

ReactGA.initialize("G-3TRP0G19ZW");

export const App = () => {

  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const colors = {
    primary: {
      100: "#0c1F41",
      200: "#27EF96",
      300: "#10DE82",
      400: "#0EBE6F",
      500: "#CEB688",
      600: "#0c1F41",
      700: "#0c1F41",
      800: "#075C37",
      900: "#064C2E",
    },
  };

  const theme = extendTheme({
    fonts: {
      body: "Montserrat, sans-serif",
      heading: "Montserrat, sans-serif",
    },
    colors: {
      ...colors, // Merge the custom colors
    },
    styles: {
      global: {
        body: {
          direction: selectedLanguage === 'ar' ? 'rtl' : 'ltr',
        },
      },
    },
  });

  return (
    <LocalizationProvider>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <ColorModeScript initialColorMode="light" />
        <CookieConsent
          location="bottom"
          buttonText={
            <Flex alignItems="center" p="0.5rem" gap="5px">
              <FaCookie /> All good!
            </Flex>
          }
          cookieName="allianceCapitalCookieAcceptatie"
          style={{
            background: "#0c1F41",
            display: "block",
            width: "100%",
            minHeight: "90px",
            padding: "20px", // Optional padding for the content
          }}
          buttonStyle={{
            color: "#fff",
            fontSize: "16px",
            fontWeight: "bold",
            backgroundColor: "#CEB688",
            textAlign: "center",
            borderRadius: '20px'
          }}
          //disableStyles={true}
          expires={150}
          visible="byCookieValue"
          overlay={true}
        >
          <Flex maxWidth="80%">
            <Text>
              This website uses cookies to improve your user experience. By using cookies, we can remember information and gain insight into how you use our website. This helps us to optimize the website and provide you with the best possible user experience.
            </Text>
          </Flex>
          <Box position="absolute" right={10} bottom={8} opacity={0.5}>
            <FaCookie size={140} />
          </Box>
        </CookieConsent>
        <BrowserRouter>
          <Box maxWidth="1200px" mx="auto" height={90}>
            <Header />
          </Box>
          <Divider marginTop="-2rem" />
          <Routes>
            <Route path="/" element={<Navigate to="/welcome" />} />
            {/* <Route path="/" element={<Welkom />} /> */}
            <Route path="/welcome" element={<Welkom />} />
            <Route path="/golden-visa" element={<GoldenVisa />} />
            <Route path="/start-to-invest" element={<StartInvestment />} />
            <Route path="/invest" element={<Investeren />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/key-risks" element={<KeyRisks />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider></LocalizationProvider>
  );
};
