import {
  Grid,
  Image,
  Flex,
  Heading,
  GridItem,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Box,
  useToast,
} from "@chakra-ui/react";
import Footer from "./components/Footer";
import CustomStepper from "./components/Stepper";
import { FormData } from "./constants/types";
import { useState } from "react";
import { MdCheckCircle } from "react-icons/md";
import ReactGA from "react-ga4";
import { useLocalization } from "./context/LocalizationContext";

const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");

ReactGA.send({
  hitType: "pageview",
  page: "/start-to-invest",
  title: "Start Investing | Alliance Capital",
});

export const StartInvestment = () => {
  const { translations } = useLocalization();

  document.title = "Start Investing | Alliance Capital";
  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const [naam, setNaam] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSending, setIsSending] = useState(false);
  const toast = useToast();
  const resetForm = () => {
    setResetFlag(true);
    setTimeout(() => {
      setResetFlag(false);
    }, 0);
  };



  const handleFormSubmit = async (formData: FormData) => {
    setIsSending(true);
    // Handle form submission here, e.g., send email
    try {
      const response = await fetch(
        "https://alliance-capital.nl/sys/sendmail_capital.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("Email sent successfully");
        setNaam(formData.fullName);
        resetForm();
        onOpen();
      } else {
        toast({
          title: "Unknown error occurred",
          description:
            "An unknown error has occurred. Please try again!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast({
        title: "Server error occurred",
        description: `error: ${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
      ReactGA.event({
        category: "Application Start Investing",
        action: "Successfully Submitted",
        label: `By: ${formData.fullName}`, // optional
        //value: 99, // optional, must be a number
        //nonInteraction: true, // optional, true/false
        //transport: "xhr", // optional, beacon/xhr/image
      });
    }
  };

  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              align="center"
              justify="center"
              marginBottom="-10px"
              marginTop="1rem"
            >
              <Box color="green.500" size={58} as={MdCheckCircle} />
            </Flex>

            <Heading size="md" p="1rem" textAlign="center">
              Congratulations {naam}!
            </Heading>
            <Text textAlign="center">
              Congratulations on your first step into a financially free future.
            </Text>
            <Text textAlign="center">
              You will be called within 24 hours to discuss everything
              and to make round.
            </Text>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Fade>
        <Heading
          textAlign="center"
          size="lg"
          color="primary.600"
          marginTop="2rem"
        >
          {translations.home_hero_meeting}
        </Heading>
        <Text
          textAlign="center"
          size="md"
          color="primary.600"
          marginBottom="1rem"
        >
          {translations.stepper_index_text}
        </Text>

        <Grid
          maxWidth="1200px"
          mx="auto"
          p={{ base: "1rem", md: "2rem" }}
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          gap={5}
          marginBottom="2rem"
        >
          <GridItem>
            <Flex justifyContent="center" height={{ base: "auto", md: "80vh" }}>
              <Zoom>
                <Image
                  src={require(`./assets/about-us-hero-${translations.lang_code}.png`)}
                  alt="About us Hero Image"
                  borderTopRadius="lg"
                  loading="lazy"
                  maxW="100%"
                  maxH="100%"
                />
              </Zoom>
            </Flex>
          </GridItem>
          <GridItem>
            <CustomStepper
              onFormSubmit={handleFormSubmit}
              resetFlag={resetFlag}
              isSending={isSending}
            />
          </GridItem>
        </Grid>

        <Footer />
      </Fade>
    </>
  );
};
