import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { useLocalization } from "../context/LocalizationContext";
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal, Key } from "react";



function CustomAccordion() {
  const { translations } = useLocalization();

  const items = translations.faqItems;
  return (
    <Accordion
      size="xl"
      allowToggle
      minW={{ base: "", md: "1200px" }}
      gap="20px"
    >
      {items.map((item: { title: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; content: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }, index: Key | null | undefined) => (
        <AccordionItem
          key={index}
          borderRadius="md"
          m={4}
          border="1px solid #e2e8f0"
        >
          <AccordionButton padding="1rem">
            <Box
              as="span"
              flex="1"
              textAlign="left"
              color="primary.600"
              fontWeight="bold"
              fontSize="md"
            >
              {item.title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>{item.content}</AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default CustomAccordion;
