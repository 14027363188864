import { Grid, GridItem } from "@chakra-ui/react";
import HeroTitle from "./components/HeroTitle";
import Footer from "./components/Footer";
import { useLocalization } from "./context/LocalizationContext";
import ReviewCard from "./components/ReviewCard";
const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");

export const Testimonials = () => {
    const { translations, selectedLanguage, setLanguage } = useLocalization();

    document.title = `${translations.what_our_investors_say} | Alliance Capital`;



    return (
        <>
            <Grid
                maxWidth="1200px"
                mx="auto"
                justifyContent="center"
                alignItems="center"
                p={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
            >
                <HeroTitle

                    title={translations.what_our_investors_say}
                />

<Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "1rem" }}
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={5}
            marginBottom="2rem"
          >
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="A. Katrina"
                  text="Investing with Alliance Capital Dubai
                  has been exceptional. Their transparent
                  and professional approach, coupled
                  with strategic insights, has surpassed
                  my expectations. The guaranteed
                  returns, coupled with personalized
                  service, make them a standout choice
                  for anyone seeking a reliable and
                  rewarding investment partner. I highly
                  recommend Alliance Capital for their
                  commitment to excellence and
                  consistent results.
                  "
                  investering={150000}
                />

              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="E. Aboulame"
                  text="So, I jumped into the investment game
                  with Alliance Capital Dubai, and it's
                  been pretty sweet. No fancy jargon—
                  just clear communication and solid
                  returns that make me smile. They know
                  their stuff, keep things interesting, and,
                  most importantly, deliver on the
                  promises. Thumbs up from this investor!
                  🚀
                  
                  "
                  investering={700000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="N. Plessen"
                  text="Never thught that there will be such
                  thing as a guaranteed roi. Well Milad
                  did a good job with Alliance Capital. He
                  knows what he is doing and he knows
                  how to create a win - win situation.
                  Keep up the great work! Looking
                  forward to keep investing with you
                  guys."
                  investering={100000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="H. Hamza"
                  text="Choosing Alliance Capital Dubai for my
                  investment was a wise move. The
                  team's transparent and strategic
                  approach has not only ensured steady
                  returns but has also instilled confidence
                  in their expertise. Their commitment to
                  professionalism and client satisfaction
                  sets them apart. In summary, a solid
                  choice for anyone looking for a
                  trustworthy partner in their investment
                  journey."
                  investering={75000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="O. Koniu"
                  text="Choosing Alliance Capital Dubai for my
                  investment has been a journey marked
                  by strategic brilliance. Their
                  commitment to transparency and
                  meticulous financial strategies has
                  consistently translated into noteworthy
                  returns. The team's professionalism
                  shines through, making Alliance Capital
                  a reliable choice for those seeking a
                  formal and dependable investment
                  partner.
                  "
                  investering={550000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="A. van Leer"
                  text="Venturing into the investment domain
                  with Alliance Capital Dubai has been
                  nothing short of a strategic triumph.
                  The team's unwavering commitment to
                  transparency and a systematic
                  approach to financial management
                  have set the stage for a highly
                  satisfactory investment journey.
                  The returns generated are a testament
                  to the astute market navigation
                  orchestrated by Alliance Capital.
                  Consistency in performance has
                  instilled a sense of confidence,
                  surpassing my initial expectations."
                  investering={150000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="O. Khalilzadeh"
                  text="I decided to roll the dice with Alliance
                  Capital Dubai for my investments, and
                  let me tell you, it's been a ride worth
                  talking about. These folks got a knack
                  for making the financial game look like
                  a walk in the park.
                  Returns? Oh, they're not just good;
                  they're the kind that puts a grin on your
                  face. Alliance Capital’s way of doing
                  things is refreshing—no fancy jargon,
                  just straightforward talk that even a
                  finance newbie like me can vibe with.
                  The crew? Super chill. They've got that
                  cool mix of professionalism and
                  friendliness."
                  investering={825000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="O. Conor"
                  text="Choosing Alliance Capital Dubai for my
                  investment proved to be a prudent
                  decision. Their commitment to
                  transparency and strategic financial
                  practices set the stage for a reliable
                  and rewarding investment journey.
                  The Returns: Impressive and Consistent
                  What stands out most is the impressive
                  and consistent returns. Alliance
                  Capital's approach to navigating
                  market dynamics has translated into
                  financial success, exceeding my initial
                  expectations.
                  "
                  investering={450000}
                />
              </Zoom>
            </GridItem>
          </Grid>
            </Grid>
            <Footer />
        </>
    );
};
