import React, { useEffect, useState } from "react";
import {
  Link,
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Image,
  useDisclosure,
  Select,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import logo from "../assets/logo-compressed.png";
import { useLocalization } from "../context/LocalizationContext";

interface MenuItemProps {
  isLast?: boolean;
  to?: string;
  children: React.ReactNode;
}

interface MenuToggleProps {
  toggle: () => void;
  isMenuOpen: boolean;
}

interface MenuLinksProps {
  isMenuOpen: boolean;
  onOpen: () => void;
}

interface HeaderContainerProps {
  children: React.ReactNode;
}

const Header: React.FC = (props) => {
  const [isMenuOpen, setIsOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggle = () => setIsOpen(!isMenuOpen);


  return (
    <>
      <HeaderContainer {...props}>
        <Link as="a" href="/welcome">
          <Image src={logo} width="8rem" marginTop={2.5} marginLeft={2.5} alt="Alliance Capital Logo" />
        </Link>
        <MenuToggle toggle={toggle} isMenuOpen={isMenuOpen} />
        <MenuLinks isMenuOpen={isMenuOpen} onOpen={onOpen} />
        <>
          <Drawer isOpen={isMenuOpen} placement="right" onClose={toggle}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader><Image src={logo} width="8rem" marginTop={2.5} marginLeft={2.5} alt="Alliance Capital Logo" /></DrawerHeader>
              <DrawerBody>
                <MenuLinks isMenuOpen={isMenuOpen} onOpen={onOpen} />
              </DrawerBody>
            </DrawerContent>
          </Drawer></>
      </HeaderContainer>
    </>
  );
};

const CloseIcon: React.FC = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Sluiten</title>
    <path
      fill="#0c1F41"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon: React.FC = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="#0c1F41"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle: React.FC<MenuToggleProps> = ({ toggle, isMenuOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle} marginTop={3.5} marginRight={3.5}>
      {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const linkStyles = {
  fontWeight: "semibold",
  fontSize: "large",
  _hover: {
    textDecoration: "none",
    color: "#CEB688",
    fontWeight: "semibold",
  },
};

const MenuItem: React.FC<MenuItemProps> = ({ children, isLast, to = "/" }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <Link href={to} {...linkStyles}>
      <Text
        display="block"
        //fontWeight="bold"
        textDecoration={isActive ? "underline" : "none"}
        textDecorationThickness="3px"
        textDecorationColor={"primary.500"}
        textUnderlineOffset="6px"
        fontSize="md"

      >
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks: React.FC<MenuLinksProps> = ({ isMenuOpen, onOpen }) => {
  const { translations, selectedLanguage, setLanguage } = useLocalization();

  const handleChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value);
  };

  return (
    <Box
      display={{ base: isMenuOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={12}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem to="/welcome">{translations.header_menu_home}</MenuItem>
        <MenuItem to="/about-us">{translations.header_menu_aboutus}</MenuItem>
        <MenuItem to="/golden-visa">{translations.header_menu_goldenvisa} </MenuItem>
        <MenuItem to="/contact">{translations.header_menu_contact} </MenuItem>
        <MenuItem to="/start-to-invest" isLast>
          <Button
            size="md"
            rounded="md"
            padding="1rem"
            color="primary.500"
            borderColor="primary.500"
            borderWidth="2px"
            _hover={{
              bg: "primary.100",
              borderColor: "primary.600",
            }}
          >
            {translations.header_menu_bookmeeting}
          </Button>

        </MenuItem>
        <Box p={2} marginLeft={"-10"} >
          <Select value={selectedLanguage} onChange={handleChangeLanguage} width="fit-content" color="primary.500"
            borderColor="primary.500"
            borderWidth="2px"
            _hover={{
              bg: "primary.100",
              borderColor: "primary.600",
            }}>
            <option value="en">EN</option>
            <option value="nl">NL</option>
            {/* <option value="ar">AR</option> */}
          </Select>
        </Box>
      </Stack>
    </Box>
  );
};

const HeaderContainer: React.FC<HeaderContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      as="nav"
      //align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      //mb={8}
      //p={8}
      bg={["transparent", "transparent", "transparent", "transparent"]}
      color={["primary.700", "primary.700", "primary.700", "primary.700"]}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Header;
