// LocalizationContext.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import enTranslations from '../locales/en';
import arTranslations from '../locales/en';
import nlTranslations from '../locales/nl';

interface LocalizationProviderProps {
  children: ReactNode;
}

interface LocalizationContextProps {
  translations: Record<string, any>;
  selectedLanguage: string;
  setLanguage: (language: string) => void;
}

const LocalizationContext = createContext<LocalizationContextProps | undefined>(undefined);

export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const [translations, setTranslations] = useState(enTranslations);

  const setLanguage = (language: string) => {
    setSelectedLanguage(language);
    localStorage.setItem('selectedLanguage', language);

    // Set translations based on the selected language
    switch (language) {
      case 'ar':
        setTranslations(arTranslations);
        break;
      case 'nl':
        setTranslations(nlTranslations);
        break;
      default:
        setTranslations(enTranslations);
    }

    window.location.reload();
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);

      // Set translations based on the stored language
      switch (storedLanguage) {
        case 'ar':
          setTranslations(arTranslations);
          break;
        case 'nl':
          setTranslations(nlTranslations);
          break;
        default:
          setTranslations(enTranslations);
      }
    }
  }, []);

  const contextValue: LocalizationContextProps = {
    translations,
    selectedLanguage,
    setLanguage,
  };

  return (
    <LocalizationContext.Provider value={contextValue}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error('useLocalization must be used within a LocalizationProvider');
  }
  return context;
};
