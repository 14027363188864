export const numberFormat = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // Changed from "EUR" to "USD"
  }).format(value);

export const numberFormatVal = (value: number, valuta: string, langCode: string) =>
  new Intl.NumberFormat(langCode, {
    style: "currency",
    currency: valuta, // Changed from "EUR" to "USD"
  }).format(value);