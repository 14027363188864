const t = {

    lang_code: 'nl',
    valute_sign: '€',
    month: 'maand',
    months: 'maanden',
    in: 'in',
    what_our_investors_say: 'Wat onze investeerder zeggen',
    what_our_investorts_say_button: 'Lees meer',

    disclaimer_investment: 'Onder toezicht en in samenwerking met DIFC (Dubai International Financial Center)',


    // CALL TO ACTION
    call_to_action_title: 'Klaar voor een financieel stabiele toekomst?',
    call_to_action_subtitle: 'Start vandaag nog jouw investering!',
    call_to_action_button: 'Boek een meeting',


    // ##Header
    header_menu_home: 'Homepagina',
    header_menu_aboutus: 'Over ons',
    header_menu_goldenvisa: 'Golden Visa',
    header_menu_contact: 'Contact',
    header_menu_bookmeeting: 'Boek een meeting',

    // #Home Page
    home_hero_title: '24% Rendement in 30 maanden',
    home_hero_subtitle: 'Geen onnodig poespas. Contracten van 20, 24, 30, 32, of 36 maanden, vaste uitbetaling per maand en een gegarandeerde rendement ongeacht wat de markt doet.',
    home_hero_bulletpoints: {
        1: '100% Kapitaalbescherming',
        2: 'Geen Verborgen Kosten',
        3: 'Maandelijkse Uitbetaling'
    },
    home_hero_meeting: 'Plan een meeting in',
    home_call_title: 'We leveren zorgvuldig beheerde beleggingsoplossingen met een hoog rendement die zijn ontworpen om aan de uiteenlopende voorkeuren van beleggers te voldoen.',
    home_call_subtitle: 'Alliance Capital zorgt voor een stressvrije beleggingservaring en biedt zorgvuldig beheerde, hoogrenderende oplossingen die zijn afgestemd op de uiteenlopende voorkeuren van beleggers. Ons toegewijde team bij Alliance Capital zorgt voor alles en biedt klanten het comfort te weten dat alle zorgen vakkundig worden afgehandeld, zodat ze kunnen ontspannen en kunnen genieten van geoptimaliseerde rendementen tijdens hun beleggingstraject.',
    home_values_bulletpoints: {
        1: 'Hoge Vaste Rendementen',
        2: 'Winstdelingsmodel',
        3: 'Onze Garantie'
    },
    home_values_bulletpoints_txt: {
        1: 'Bouw uw vermogen op met een gepersonaliseerde, gediversifieerde portefeuille met deskundig advies, volledig door ons verzorgd.',
        2: 'Opererend volgens een model van winstdeling verdienen wij geld wanneer onze investeerders geld verdienen en ons succes hangt af van uw succes.',
        3: 'Alliance Capital garandeert uw welvarende toekomst. Vertrouw op onze expertise voor gegarandeerde groei en financiële zekerheid.'
    },

    // #Targets
    targets_portfolio: 'Portfolio Waarde',
    targets_payouts: 'Uitbetalingen per jaar',
    targets_properties: 'Properties',

    // # Investment calculator
    investment_calculator: 'Investeringscalculator',
    investment_calculator_subtitle: 'Bereken gemakkelijk je rendement.',
    investment_calculator_desire: 'Gewenste investering',
    investment_calculator_total: 'Totale investering',
    investment_calculator_return: 'Totale rendement',
    investment_calculator_earnback: 'Totaal terug verdiend',
    investment_calculator_expected_text: 'Verwacht investeringsrendement van',
    investment_calculator_monthlypayout: 'Maandelijkse Uitbetaling',

    rewards_title: 'Ontvang voordelen tijdens het investeren.',
    rewards_subtitle: 'Naarmate je meer investeert, nemen je voordelen toe! Alle investeerders worden automatisch lid van onze membership club.',
    rewards_investementof: 'Bij een investering van',
    rewards_youget: 'ontvang je',
    rewards_amount: 'Hoeveel wil je investeren?',

    "startup": {
        cashback: "Geen cashback op je volgende investering",
        refferal: "€500 per verwijzing",
        welcome: "Geen welkomspakket",
        holiday: "Geen verblijf in Dubai",
        flight: "Geen First class ticket heen en terug",
    },
    "elite": {
        cashback: "5% cashback op je volgende investering",
        refferal: "€750 per verwijzing",
        welcome: "Welkomspakket t.w.v. € 500",
        holiday: "Geen verblijf in Dubai",
        flight: "Geen First class ticket heen en terug",

    },
    "vip": {
        cashback: "7% cashback on your next investment",
        refferal: "€1250 per verwijzing",
        welcome: "Welkomspakket t.w.v. € 1000",
        holiday: "7 dagen verblijf in Dubai",
        flight: "Geen First class ticket heen en terug",

    },
    "platinum": {
        cashback: "10% cashback on your next investment",
        refferal: "€2000 per verwijzing",
        welcome: "Welkomspakket t.w.v. € 2000",
        holiday: "14 days stay in Dubai",
        flight: "First class ticket heen en terug",

    },

    realestate_unites_title: 'Vastgoed verenigd de sterke punten van alle soorten investeringen',
    realestate_unites_text: 'Het behoort tot de meest cruciale categorieën van activa om te bezitten en om op de lange termijn vermogen op te bouwen.',

    "capitalGrowth": {
        heading: "Kapitaalgroei",
        text: "Buiten het genereren van maandelijkse passieve inkomsten, groeit onroerend goed in waarde, net als aandelen, en dit draagt bij aan het opbouwen van langetermijnvermogen voor investeerders.",
    },
    "passiveIncome": {
        heading: "Passief inkomen",
        text: "Vastgoed levert een betrouwbare passieve inkomstenstroom op, net als obligaties, in de vorm van regelmatige maandelijkse huurinkomsten.",
    },
    "protectionAgainstInflation": {
        heading: "Bescherming tegen inflatie",
        text: "Onroerend goed is de oudste activaklasse in de geschiedenis en heeft altijd gefungeerd als een uitstekende bescherming tijdens inflatoire periodes, net zoals goud.",
    },
    "storageOfWealth": {
        heading: "Opslag van rijkdom",
        text: "Iedereen droomt van een financieel stabiele toekomst. Met onroerend goed van Alliance Capital ben je verzekerd van deze mooie toekomst.",
    },


    // About
    grow_with_alliance: `Groei met Alliance Capital`,
    about_ready: 'Klaar om je financiële toekomst te versterken en je dromen waar te maken?',
    about_alliance: 'Over Alliance Capital',
    about_alliance_text: 'Je investeringsavontuur in Dubai begint hier!',
    about_us: 'Over ons',
    contact_us: 'Contacteer ons',
    about_us_text: 'Onze naam, "Alliance Capital," weerspiegelt onze toewijding om samen te werken met onze investeerders. Wij geloven in de kracht van partnerschap en delen onze winst met jou. Jouw succes is ons succes, en dat is de kern van wie we zijn.',
    about_us_text2: 'Bij Alliance Capital begrijpen we ook dat vertrouwen de basis vormt van elke succesvolle investering. Daarom gaan we verder dan alleen woorden; we bieden transparantie en integriteit in al onze transacties. We streven ernaar om de toekomst van jouw financiën te beschermen en te laten groeien, zodat jij je kunt richten op wat echt belangrijk is in het leven.',
    about_us_text3: 'Onze vastberadenheid om te innoveren en vooruit te denken in de financiële wereld is wat ons onderscheidt. Bij Alliance Capital staan we klaar om je te begeleiden op jouw reis naar financiële zekerheid. Dus waar wacht je nog op? Laten we samenwerken, investeren en de toekomst van jouw financiën veiligstellen.',
    about_us_text4: 'Sluit je aan bij de Alliance Capital-familie en ontdek de kracht van investeren met vertrouwen. Samen bouwen we aan een solide financiële toekomst.',
    about_ready_text1: 'Stel je voor, een plek waar je financiële dromen kunnen uitkomen, waar investeren een kunst wordt en winst een belofte is. Welkom bij Alliance Capital, waar we investeren naar een geheel nieuw niveau tillen.',
    about_ready_text2: 'Bij Alliance Capital begrijpen we dat de wereld van financiën vaak onvoorspelbaar en vol risico\'s kan zijn. Daarom hebben we een oase van zekerheid gecreëerd in de vorm van onze unieke investeringsmogelijkheden. Met ons vaste contract van 16, 18 of 24 maanden bieden we niet alleen stabiliteit, maar ook een vast percentage rendementop je investering. Het is als een reis op een gestage koers naar financiële groei.',

    // GOLDEN VISA
    "golden_visa_heading_text": "Alliance Capital maakt het verkrijgen van uw Golden Visa eenvoudiger.",
    "golden_visa_heading_subtitle": "Investeer vanaf elke plek ter wereld en verzeker uzelf van uw UAE Golden Visa.",
    "golden_visa_whatis": "Wat is een Golden Visa?",
    "golden_visa_whatis_text": "Een Golden Visa van de Verenigde Arabische Emiraten (VAE) is een programma voor langdurig verblijf dat verschillende voordelen biedt, en wij maken het beschikbaar voor iedereen die ten minste AED 2.000.000 (ongeveer €515.000) investeert via ons platform.",
    "golden_visa_smartways_title": "De slimste manier om uw Golden Visa te verkrijgen",


    "longTermResidenceRights": {
        "heading": "Verkrijg langdurige verblijfsrechten",
        "text": "een Golden Visa biedt een 10-jarige, vernieuwbare verblijfsvergunning. Dit betekent dat je een langere periode in de Verenigde Arabische Emiraten kunt verblijven, leven en werken zonder je zorgen te hoeven maken over het vernieuwen van je visum."
    },
    "bringWholeFamily": {
        "heading": "Neem je hele familie mee",
        "text": "Als houder van een Golden Visa kun je je hele familie als sponsors meebrengen, inclusief je echtgenoot en kinderen, ongeacht hun leeftijd. De hele familie heeft het recht op toegang tot eersteklas gezondheidszorg, onderwijs en werkgelegenheidskansen in de Verenigde Arabische Emiraten."
    },
    "travelWithoutRestrictions": {
        "heading": "Reis zonder beperkingen",
        "text": "Werk vanaf elke locatie, reis gemakkelijk en keer terug naar de Verenigde Arabische Emiraten zonder je zorgen te maken over je verblijfsstatus."
    },
    "enterFastestGrowingMarket": {
        "heading": "Betreed 's werelds snelstgroeiende markt",
        "text": "een Golden Visa biedt je de kans om te gedijen in het zakelijke klimaat van de Verenigde Arabische Emiraten, inclusief vrije zones, belastingvoordelen en toegang tot toptalent."
    },
    "accessibility": {
        "heading": "Toegankelijkheid",
        "text": "Investeer in de lucratieve vastgoedmarkt van Dubai vanaf elke locatie ter wereld. Hiermee kun je genieten van je langdurige verblijfsvergunning in de Verenigde Arabische Emiraten en tegelijkertijd je beleggingsportefeuille diversifiëren."
    },
    "diversification": {
        "heading": "Diversificatie",
        "text": "Verspreid je kapitaal over verschillende eersteklas vastgoedobjecten in Dubai om een gediversifieerde vastgoedportefeuille te creëren met verminderd risico en een groter potentieel rendement."
    },
    "investWithoutWorries": {
        "heading": "Investeer zonder zorgen",
        "text": "Ervaar een naadloos, op mobiel gericht digitaal platform dat tijd en geld bespaart door papierwerk te elimineren en beheer je vastgoedinvesteringen van begin tot eind."
    },
    "bestInvestmentDeals": {
        "heading": "De beste investeringsdeals",
        "text": "Genereer sneller huurinkomsten en zorg voor een stabiele inkomstenstroom met vastgoed dat direct kan worden bezet, waardoor de noodzaak voor investeringen in projecten op basis van planning wordt geëlimineerd."
    },
    "platinumMembershipHeading": "Ontgrendel ons Platinum-lidmaatschap",
    "platinumMembershipText": "Het Capital Platinum-lidmaatschap vertegenwoordigt onze meest waardevolle klanten en wordt geleverd met een scala aan uitzonderlijke voordelen en op maat gemaakte diensten.",
    "referral": {
        "heading": "€ 2000",
        "text": "per doorverwijzing"
    },
    "cashback": {
        "heading": "10% cashback",
        "text": "op je volgende investering"
    },
    "welcomePack": {
        "heading": "Welkomstpakket",
        "text": "ter waarde van $2000"
    },
    "stayInDXB": {
        "heading": "Verblijf DXB",
        "text": "14 dagen, incl. eersteklas ticket"
    },
    "founderName": "Milad Ma",
    "founderTitle": "Oprichter & CEO",
    "founderQuote": "Als het enige platform dat het mogelijk maakt voor Nederlanders om in Dubai te investeren met een vast uitbetalings- en rendementspercentage per jaar, hebben we nu ook een partnerschap met DIFC om mensen die hoger investeren te voorzien van een Golden Visa. Dit is een gouden kans voor iedereen die een stukje Dubai wil. Deze samenwerking bewijst dat wij als investeringsplatform dag en nacht werken om een win-win-win situatie te bereiken.",
    // FAQ

    "faqHeading": "FAQ",
    "faqDescription": "Hier zijn enkele veelgestelde vragen over het verkrijgen van uw Golden Visa via Alliance Capital! Aarzel niet om contact met ons op te nemen als u vragen heeft; ons team staat voor u klaar!",
    "faqItems": [
        {
            "title": "Wat is een Golden Visa?",
            "content": "een Golden Visa van de Verenigde Arabische Emiraten biedt een 10-jarige, vernieuwbare verblijfsvergunning en een reeks voordelen, zoals gezinssponsoring, toegang tot eersteklas gezondheidszorg- en onderwijsservices, zakelijke en werkgelegenheidskansen, en de flexibiliteit om te reizen zonder u zorgen te hoeven maken over uw verblijfsstatus."
        },
        {
            "title": "Moet ik een bestaande inwoner van Dubai zijn om in aanmerking te komen voor een Golden Visa bij Alliance Capital?",
            "content": "Nee, u hoeft geen inwoner van Dubai te zijn om in aanmerking te komen. Elke wereldwijde investeerder die voldoet aan de vereisten voor professionele investeerders komt in aanmerking om te investeren bij Alliance Capital voor een Golden Visa."
        },
        {
            "title": "Wat is het vereiste minimum investeringsbedrag om in aanmerking te komen voor een Golden Visa?",
            "content": "Het vereiste minimum investeringsbedrag is AED 2.000.000 ($ 515.000)."
        },
        {
            "title": "Kan ik mijn eigendom verkopen en toch mijn Golden Visa behouden?",
            "content": "Ja, u kunt uw eigendom verkopen en uw Golden Visa behouden, zolang u de opbrengst herinvesteert in een andere kwalificerende investering in de VAE."
        },
        {
            "title": "Zijn er extra kosten of toeslagen voor het aanvragen van een Golden Visa bij Alliance Capital?",
            "content": "Ja, er zijn servicekosten totaal AED 6.100,00 bij het aanvragen van een Golden Visa. Let op: dit bedrag is exclusief het bedrag aan belasting over de toegevoegde waarde (btw) van AED 76,83. Deze kosten dekken diverse administratieve en verwerkingskosten die gepaard gaan met de aanvraag."
        },
        {
            "title": "Kan ik een Golden Visa gebruiken om te werken in de VAE?",
            "content": "Ja, u kunt een Golden Visa gebruiken om te werken in de VAE, maar u moet de vereiste werkvergunningen verkrijgen en voldoen aan de eisen die door de regering van de VAE zijn gesteld."
        },
        {
            "title": "Kan ik de VAE verlaten terwijl ik een Golden Visa heb?",
            "content": "Ja, u kunt de VAE verlaten wanneer u maar wilt terwijl u een Golden Visa heeft."
        },
        {
            "title": "Hoe lang duurt het om een Golden Visa te verkrijgen?",
            "content": "De geschatte verwerkingstijd om het visum te verkrijgen is minimaal 2 weken. Let op: deze periode houdt geen rekening met onverwachte vertragingen die kunnen optreden als gevolg van externe factoren of beslissingen van de relevante autoriteiten."
        },
        {
            "title": "Is een Golden Visa gegarandeerd als ik investeer?",
            "content": "Hoewel investeren uw kansen op het verkrijgen van een visum aanzienlijk kan verbeteren, is het belangrijk op te merken dat er geen garantie is voor visumgoedkeuring. De uiteindelijke beslissing ligt bij de relevante autoriteiten, die uw aanvraag zullen beoordelen op basis van verschillende criteria, waaronder uw investering, achtergrond en naleving van specifieke programma-eisen."
        }
    ],

    // Contact
    "contact_title": "Wij zullen u persoonlijk begeleiden bij uw investering in Dubai!",
    "contactform_title": "Neem contact met ons op via het onderstaande contactformulier. We beantwoorden uw vraag zo snel mogelijk!",
    "contactform_address": "Adres",
    "contactform_contactdetails": "Contactgegevens",
    "contactform_fullname": "Volledige naam",
    "contactform_email": "E-mail",
    "contactform_message": "Bericht",
    "contactform_send": "Verzenden",
    "contact_us_hero_text": "Neem contact met ons op",

    // Stepper
    stepper_contact_details: 'Contactgegevens',
    stepper_howmuch_investement: 'Hoeveel wil je investeren?',
    stepper_error_fieldsnotempty: 'Velden mogen niet leeg zijn',
    stepper_error_fieldsnotempty_description: 'U heeft velden achtergelaten die niet leeg mogen zijn.',
    stepper_fullname: 'Voor en achternaam',
    stepper_phone: 'Telefoonnummer',
    stepper_email: 'E-mailadres',
    stepper_dia: 'Gewenste Investeringbedrag?',
    stepper_wdhfus: 'Waar heb je gehoord over ons?',
    stepper_viawho: 'Via @wie (indien van toepassing)',
    stepper_hwdy: 'Hoe snel wil je starten?',
    stepper_hwdy_1: 'Binnen 24 uur',
    stepper_hwdy_2: 'Binnen 3 dagen',
    stepper_hwdy_3: 'Binnen een week',
    stepper_hwdy_4: 'Later',
    stepper_back: 'Terug',
    stepper_next: 'Volgende',
    stepper_submit: 'Verzenden',
    stepper_index_text: 'Laat je gegevens achter en wij contacteren jou.',

    compare_title: 'Vergelijk investeringsgroepen met een vaste rendement',
    compare_text: 'Onze bewezen staat van dienst stelt ons in staat investeerders een vast rendement te bieden via Alliance Capital, terwijl we alle risico\'s zelf op ons nemen, zodat u op een veilige manier kunt beleggen.',
    none: 'None',
    annual_equivalent_rate: 'Annual Equivalent Rate',
    fyr: 'Fixed Yearly Returns',
    fr: 'Fixed Returns',
    vyr: 'Variable Yearly Returns',
    month_bond: 'Month Bond',
    month_fixed: 'Month Fixed Rate',
    term: 'Term',
    deposit: 'Deposit',
    roi: 'ROI',

    // WHY
    why_alliance: 'Waarom Alliance Capital',
    why_alliance_text: '',
    why_tab_1: 'Verzekerde ROI',
    why_tab_2: 'Maandelijkse Winstuitkering',
    why_tab_3: 'Veilige Investeringen',
    why_tab_4: 'Evenwichtige Hoge Opbrengsten',
    why_tab_5: 'Transparante Bedrijfsvoering',
    why_tab_1_text: 'Vertrouw op Alliance Capital voor consistente en veilige opbrengsten, waarbij investeerders gemoedsrust wordt geboden.',
    why_tab_2_text: 'Ervaar betrouwbare maandelijkse uitkeringen, zodat er een stabiele inkomstenstroom is om aan uw financiële doelen te voldoen.',
    why_tab_3_text: 'Met ons robuuste risicobeheer en diverse portefeuillestrategie hebben we de veiligheid van uw investeringen als prioriteit.',
    why_tab_4_text: 'Alliance Capital streeft naar aantrekkelijke opbrengsten met behoud van een zorgvuldige balans tussen rendement en veiligheid.',
    why_tab_5_text: 'We handhaven transparantie door middel van duidelijke communicatie en uitgebreide rapportage, zodat u goed op de hoogte bent van uw investeringen.',

    // 
    free_residency: 'Gratis Residency',
    no_free_residency: 'Geen Gratis Residency',
    no: 'Geen',

};

export default t;
