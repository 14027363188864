import {
  Grid,
  Image,
  Flex,
  Heading,
  GridItem,
  Button,
  Link,
  Card,
  Text,
  Wrap,
  WrapItem,
  Box,
  CardBody,
  HStack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  ButtonGroup,
  CardFooter,
  Divider,
  Stack,
  VStack
} from "@chakra-ui/react";
import ImageTabs1 from "./assets/tabs/1.png";
import ImageTabs2 from "./assets/tabs/2.png";
import ImageTabs3 from "./assets/tabs/3.png";
import ImageTabs4 from "./assets/tabs/4.png";
import BGCapital from "./assets/bg-capital.png";
import IconMoney from "./assets/dollar-icon.png";
import IconShield from "./assets/shield-icon.png";
import IconGrowth from "./assets/grow-icon.png";
import Footer from "./components/Footer";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import AchievmentNumbers from "./components/AchievmentNumbers";
import {
  FaChartLine,
  FaLock,
  FaDatabase,
  FaDollarSign,
  FaCheckCircle,
} from "react-icons/fa";
import InvestementCalculator from "./components/InvestementCalculator";
import RewardsCalculator from "./components/RewardsCalculator";
import difcLogo from "./assets/difc-logo.png";
import ReactGA from "react-ga4";
import ReviewCard from "./components/ReviewCard";
import { useRef } from "react";
import { useLocalization } from "./context/LocalizationContext";
const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");

ReactGA.send({
  hitType: "pageview",
  page: "/welcome",
  title: "Home | Alliance Capital",
});

export const Welkom = () => {
  const { translations, selectedLanguage, setLanguage } = useLocalization();
  document.title = `${translations.header_menu_home} | Alliance Ventura`;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const investmentCalculatorRef = useRef<HTMLDivElement>(null);


  const scrollToInvestmentCalculator = () => {
    if (investmentCalculatorRef.current) {
      investmentCalculatorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const tabs = [
    {
      tabName: "Step 1",
      descr: "Choose a property from our portfolio.",
      images: [
        {
          id: 1,
          imageUrl: ImageTabs1,
          altText: "Image 1",
        },
      ],
    },
    {
      tabName: "Step 2",
      descr: "Create an account to be able to set the Steps immediately.",
      images: [
        {
          id: 2,
          imageUrl: ImageTabs2,
          altText: "Image 2",
        },
      ],
    },
    {
      tabName: "Step 3",
      descr:
        "Select your desired investment amount and sign the online agreement.",
      images: [
        {
          id: 4,
          imageUrl: ImageTabs3,
          altText: "Image 2",
        },
      ],
    },
    {
      tabName: "Step 4",
      descr:
        "Congratulations! You are now a Capital member and your first payout is coming in 30 days!",
      images: [
        {
          id: 4,
          imageUrl: ImageTabs4,
          altText: "Image 2",
        },
      ],
    },
  ];

  return (
    <>
      <Fade>
        <Flex backgroundImage={BGCapital}>
          <Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "1rem" }} // Adjust padding for different screen sizes
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} // Three equal-width columns on medium and larger screens
            gap={5} // Gap between grid items
          >
            <GridItem
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Flex flexDirection="column">
                <Heading
                  size="xl"
                  color="primary.600"
                  fontWeight="bold"
                  marginBottom="1rem"
                  textAlign={{ base: "center", md: "left" }}
                  marginTop={{ base: "1rem", md: "0px" }}
                >
                  {translations.home_hero_title}
                </Heading>
                <Text textAlign={{ base: "center", md: "left" }} color={"white"} fontWeight={"bold"}>
                  {translations.home_hero_subtitle}
                </Text><br />
                <HStack><FaCheckCircle color="#008000" /> <Text textAlign={{ base: "center", md: "left" }} color={"white"} fontWeight={"bold"}>{translations.home_hero_bulletpoints[1]}</Text></HStack>
                <HStack><FaCheckCircle color="#008000" /> <Text textAlign={{ base: "center", md: "left" }} color={"white"} fontWeight={"bold"}>{translations.home_hero_bulletpoints[2]}</Text></HStack>
                <HStack><FaCheckCircle color="#008000" /> <Text textAlign={{ base: "center", md: "left" }} color={"white"} fontWeight={"bold"}>{translations.home_hero_bulletpoints[3]}</Text></HStack>


                <Wrap spacing={4}>
                  <WrapItem>
                    <Link as="a" href="/start-to-invest">
                      <Button
                        textAlign={{ base: "center", md: "left" }}
                        bgColor="primary.500"
                        size="lg"
                        marginTop="1rem"
                        rightIcon={<ArrowForwardIcon />}
                        _hover={{ bg: "primary.600", color: "white" }}
                        _active={{
                          bg: "#dddfe2",
                          transform: "scale(0.98)",
                          borderColor: "#bec3c9",
                        }}
                        _focus={{
                          boxShadow:
                            "0 0 1px 2px rgba(206, 182, 136, 1), 0 1px 1px rgba(0, 0, 0, .15)",
                        }}
                      >
                        {translations.home_hero_meeting}
                      </Button>
                    </Link>
                  </WrapItem>
                </Wrap>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex justifyContent="center">
                <Zoom>
                  <Image
                    style={{ cursor: "pointer" }}
                    onClick={scrollToInvestmentCalculator}
                    src={require(`./assets/welcome-hero-${translations.lang_code}.png`)}
                    alt="About us Image"
                    objectFit="cover"
                    loading="lazy"
                    height="90vh"

                  />
                </Zoom>
              </Flex>
            </GridItem>
          </Grid>
        </Flex>
        <AchievmentNumbers />
        <Flex backgroundColor="white" marginBottom="-3rem">
          <Grid
            maxWidth="1200px"
            minWidth={{ base: "", md: "1200px" }}
            mx="auto"
            p={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
            templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }} // Three equal-width columns on medium and larger screens
            gap={5} // Gap between grid items
          >
            <GridItem>
              <Heading size="xl" fontWeight={"medium"}>
                {translations.home_call_title}

              </Heading><br />
              <Text maxW={"800px"}>
                {translations.home_call_subtitle}

              </Text>
              {/* <Zoom>
                <Box paddingTop="2rem">
                  <TabbedImageSlider tabs={tabs} />
                </Box>
              </Zoom> */}<br /><br />



              <Grid
                maxWidth="1200px"
                minWidth={{ base: "", md: "1200px" }}

                marginBottom={20}
                //p={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
                templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} // Three equal-width columns on medium and larger screens
              //gap={6} // Gap between grid items
              >
                <GridItem marginRight={10}>

                  <Image src={IconGrowth} marginBottom={2} width={"32px"} />
                  <Text fontWeight={"bold"} fontSize={"large"} marginBottom={2}>{translations.home_values_bulletpoints[1]}
                  </Text> <Text color="gray.600">{translations.home_values_bulletpoints_txt[1]}

                  </Text></GridItem>
                <GridItem marginRight={10}><Image src={IconMoney} marginBottom={2} width={"32px"} /><Text fontWeight={"bold"} fontSize={"large"} marginBottom={2}>{translations.home_values_bulletpoints[2]}
                </Text> <Text color="gray.600">{translations.home_values_bulletpoints_txt[2]}

                  </Text></GridItem>
                <GridItem marginRight={10}><Image src={IconShield} marginBottom={2} width={"32px"} /><Text fontWeight={"bold"} fontSize={"large"} marginBottom={2}>{translations.home_values_bulletpoints[3]}
                </Text> <Text color="gray.600">{translations.home_values_bulletpoints_txt[3]}

                  </Text></GridItem>
              </Grid>



            </GridItem>
          </Grid>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          marginBottom="2rem"
          backgroundColor="gray.100"
          paddingBottom="1rem"
          id="investmentCalculator" ref={investmentCalculatorRef}
        >
          <Heading
            size="xl"
            textAlign="center"
            marginTop="2rem"
            color="primary.600"
            marginBottom="2rem"
          >
            {translations.investment_calculator}
          </Heading>

          {/* CALCULATOR */}
          <InvestementCalculator />
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="center"
          marginBottom="2rem"
          backgroundColor="white"
          paddingBottom="1rem"
        >
          <Heading
            size="xl"
            textAlign="center"
            marginTop="2rem"
            color="primary.600"
          >
            {translations.compare_title}
          </Heading><br />
          <Text maxWidth="1200px" textAlign="center" marginX="1rem">{translations.compare_text}
          </Text><br />
          {isMobile ?
            <VStack>
              <Card minW="98%" padding="1rem" border="2px" borderColor="primary.500">
                <CardBody>
                  <Image
                    src={require(`./assets/logo-compressed.png`)}
                    borderTopRadius="lg"
                    loading="lazy"
                    width="110px"
                  />
                  <Stack mt='6' spacing='3'>
                    <Text>
                      {translations.roi}: 20% {translations.fr}
                      <br />
                      {translations.term}: 20 {translations.month_fixed}
                      <br />
                      {translations.deposit}: Min €50,000
                      <br />
                    </Text>
                  </Stack>
                </CardBody>
              </Card>

              <Card minW="98%" padding="1rem" border="2px" borderColor="gray.300">
                <CardBody>
                  <Image
                    src={require(`./assets/compare/DtZArjYP6fnA77zzecMD5krMb0.webp`)}
                    borderTopRadius="lg"
                    loading="lazy"
                    width="110px"
                  />
                  <Stack mt='6' spacing='3'>
                    <Text>
                      {translations.roi}: 18% {translations.fyr}
                      <br />
                      {translations.term}: 36 {translations.month_fixed}
                      <br />
                      {translations.deposit}: Min €100,000
                      <br />
                    </Text>
                  </Stack>
                </CardBody>
              </Card>

              <Card minW="98%" padding="1rem" border="2px" borderColor="gray.300">
                <CardBody>
                  <Image
                    src={require(`./assets/compare/PTEuSVL884h8ZeALv7ditqvh0U.webp`)}
                    borderTopRadius="lg"
                    loading="lazy"
                    width="110px"
                  />
                  <Stack mt='6' spacing='3'>
                    <Text>
                      {translations.roi}: 5.53% {translations.annual_equivalent_rate}
                      <br />
                      {translations.term}: 9 {translations.month_bond}
                      <br />
                      {translations.deposit}: Min £1,000 - Max £500,000
                      <br />
                    </Text>
                  </Stack>
                </CardBody>
              </Card>

              <Card minW="98%" padding="1rem" border="2px" borderColor="gray.300">
                <CardBody>
                  <Image
                    src={require(`./assets/compare/WBVA6jzPSR8lkAHVhEXco9wk.webp`)}
                    borderTopRadius="lg"
                    loading="lazy"
                    width="110px"
                  />
                  <Stack mt='6' spacing='3'>
                    <Text>
                      {translations.roi}: 5.50% {translations.annual_equivalent_rate}
                      <br />
                      {translations.term}: 12 {translations.month_bond}
                      <br />
                      {translations.deposit}: Min £1,000 -
                      Max £1,000,000
                      <br />
                    </Text>
                  </Stack>
                </CardBody>
              </Card>

              <Card minW="98%" padding="1rem" border="2px" borderColor="gray.300">
                <CardBody>
                  <Image
                    src={require(`./assets/compare/5oxyD2SZfLBFYsz2dvTiWMg6cI.webp`)}
                    borderTopRadius="lg"
                    loading="lazy"
                    width="110px"
                  />
                  <Stack mt='6' spacing='3'>
                    <Text>
                      {translations.roi}: 5.50% {translations.annual_equivalent_rate}
                      <br />
                      {translations.term}: 12 {translations.month_bond}
                      <br />
                      {translations.deposit}: Min £5,000 - Max £1,000,000
                      <br />
                    </Text>
                  </Stack>
                </CardBody>
              </Card>

              <Card minW="98%" padding="1rem" border="2px" borderColor="gray.300">
                <CardBody>
                  <Image
                    src={require(`./assets/compare/iVfORrKuJR4FSR3dwzNy28xoWUw.webp`)}
                    borderTopRadius="lg"
                    loading="lazy"
                    width="110px"
                  />
                  <Stack mt='6' spacing='3'>

                    <Text>
                      {translations.roi}: 2.15% {translations.vyr}
                      <br />
                      {translations.term}: {translations.none}
                      <br />
                      {translations.deposit}: Min £1,000 - Max £1,000,000
                      <br />
                    </Text>
                  </Stack>
                </CardBody>
              </Card>

            </VStack>
            :
            <TableContainer>
              <Table variant='simple' size='lg'>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>{translations.roi}</Th>
                    <Th>{translations.term}</Th>
                    <Th>{translations.deposit}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr border="2px" borderColor="primary.500">
                    <Td><Image
                      src={require(`./assets/logo-compressed.png`)}
                      borderTopRadius="lg"
                      loading="lazy"
                      width="90px"

                    /></Td>
                    <Td>20% {translations.fr}</Td>
                    <Td>20 {translations.month_fixed}</Td>
                    <Td>Min €50,000</Td>
                  </Tr>
                  <Tr>
                    <Td><Image
                      src={require(`./assets/compare/DtZArjYP6fnA77zzecMD5krMb0.webp`)}
                      borderTopRadius="lg"
                      loading="lazy"
                      width="90px"

                    /></Td>
                    <Td>18% {translations.fyr}</Td>
                    <Td>36 {translations.month_fixed}</Td>
                    <Td>Min €100,000</Td>
                  </Tr>
                  <Tr>
                    <Td><Image
                      src={require(`./assets/compare/PTEuSVL884h8ZeALv7ditqvh0U.webp`)}
                      borderTopRadius="lg"
                      loading="lazy"
                      width="70px"

                    /></Td>
                    <Td>5.53% {translations.annual_equivalent_rate}</Td>
                    <Td>9 {translations.month_bond}</Td>
                    <Td>Min £1,000<br />
                      Max £500,000</Td>
                  </Tr>
                  <Tr>
                    <Td><Image
                      src={require(`./assets/compare/WBVA6jzPSR8lkAHVhEXco9wk.webp`)}
                      borderTopRadius="lg"
                      loading="lazy"
                      width="70px"

                    /></Td>
                    <Td>5.50% {translations.annual_equivalent_rate}</Td>
                    <Td>12 {translations.month_bond}</Td>
                    <Td>Min £1,000<br />
                      Max £1,000,000</Td>
                  </Tr>
                  <Tr>
                    <Td><Image
                      src={require(`./assets/compare/5oxyD2SZfLBFYsz2dvTiWMg6cI.webp`)}
                      borderTopRadius="lg"
                      loading="lazy"
                      width="70px"

                    /></Td>
                    <Td>5.50% {translations.annual_equivalent_rate}</Td>
                    <Td>12 {translations.month_bond}</Td>
                    <Td>Min £5,000<br />
                      Max £1,000,000</Td>
                  </Tr>
                  <Tr>
                    <Td><Image
                      src={require(`./assets/compare/iVfORrKuJR4FSR3dwzNy28xoWUw.webp`)}
                      borderTopRadius="lg"
                      loading="lazy"
                      width="70px"

                    /></Td>
                    <Td>2.15% {translations.vyr}</Td>
                    <Td>{translations.none}</Td>
                    <Td>Min £1,000<br />
                      Max £1,000,000</Td>
                  </Tr>

                </Tbody>
              </Table>
            </TableContainer>}
<br/><br/>
          <Heading
            size="xl"
            //textAlign="center"
            marginTop="2rem"
            color="primary.600"
          >
            {translations.why_alliance}
          </Heading><br />
          {isMobile ? <Accordion defaultIndex={[0]} allowToggle >
            <AccordionItem >
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    <strong>{translations.why_tab_1}</strong>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {translations.why_tab_1_text}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    <strong>{translations.why_tab_2}</strong>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {translations.why_tab_2_text}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    <strong>{translations.why_tab_3}</strong>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {translations.why_tab_3_text}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    <strong> {translations.why_tab_4}</strong>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {translations.why_tab_4_text}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    <strong> {translations.why_tab_5}</strong>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {translations.why_tab_5_text}
              </AccordionPanel>
            </AccordionItem>

          </Accordion> : <Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "1rem" }}
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={5}
            marginBottom="2rem"
          >
            <GridItem minW="600px">
              <Accordion defaultIndex={[0]} allowToggle >
                <AccordionItem >
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left'>
                        <strong>{translations.why_tab_1}</strong>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {translations.why_tab_1_text}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left'>
                        <strong>{translations.why_tab_2}</strong>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {translations.why_tab_2_text}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left'>
                        <strong>{translations.why_tab_3}</strong>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {translations.why_tab_3_text}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left'>
                        <strong> {translations.why_tab_4}</strong>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {translations.why_tab_4_text}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left'>
                        <strong> {translations.why_tab_5}</strong>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {translations.why_tab_5_text}
                  </AccordionPanel>
                </AccordionItem>

              </Accordion>
            </GridItem>
            <GridItem flexDirection={"row"}>

              <Flex>

                <Image
                  src={require(`./assets/gofc.jpg`)}
                  alt="Picture of business people"
                  borderRadius="10px"
                  loading="lazy"
                  height="25vh"
                />

                <Image
                  src={require(`./assets/gofx.jpg`)}
                  alt="Picture of business people"
                  marginLeft="2rem"
                  marginTop="2.5rem"
                  loading="lazy"
                  height="25vh"
                  borderRadius="10px"
                />

              </Flex>
            </GridItem>
          </Grid>}

        </Flex>
        {/* <Flex
          flexDirection="column"
          alignItems="center"
          marginBottom="2rem"
          backgroundColor="white"
          paddingBottom="1rem"
        >
          <RewardsCalculator />
        </Flex> */}
        <Flex bgColor="gray.100" flexDirection="column" alignItems="center">
          <Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "1rem" }}
            templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
            gap={5}
            marginBottom="4rem"
          >
            <GridItem
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <Zoom>
                <Heading
                  size="xl"
                  fontWeight="bold"
                  color="primary.600"
                  marginBottom="1rem"
                >
                  {translations.realestate_unites_title}
                </Heading>
                <Text color="primary.600">
                  {translations.realestate_unites_text}

                </Text>
              </Zoom>
            </GridItem>
            <GridItem marginTop={{ base: "1rem", md: "120px" }}>
              <Zoom>
                <Card
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                >
                  <CardBody>
                    <Box
                      bgColor="gray.200"
                      h="40px"
                      w="40px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="10px"
                    >
                      <FaChartLine size={32} color="#0c1F41" />
                    </Box>
                    <Heading size="md" fontWeight="bold" color="primary.500">
                      {translations.capitalGrowth.heading}
                    </Heading>
                    <Text color="primary.600">
                      {translations.capitalGrowth.text}
                    </Text>
                  </CardBody>
                </Card>

                <Card
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                >
                  <CardBody>
                    <Box
                      bgColor="gray.200"
                      h="40px"
                      w="40px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="10px"
                    >
                      <FaDollarSign size={32} color="#0c1F41" />
                    </Box>
                    <Heading size="md" fontWeight="bold" color="primary.500">
                      {translations.passiveIncome.heading}
                    </Heading>
                    <Text color="primary.600">
                      {translations.passiveIncome.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
            <GridItem marginTop={{ base: "1rem", md: "80px" }}>
              <Zoom>
                <Card
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                >
                  <CardBody>
                    <Box
                      bgColor="gray.200"
                      h="40px"
                      w="40px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="10px"
                    >
                      <FaLock size={32} color="#0c1F41" />
                    </Box>
                    <Heading size="md" fontWeight="bold" color="primary.500">
                      {translations.protectionAgainstInflation.heading}
                    </Heading>
                    <Text color="primary.600">
                      {translations.protectionAgainstInflation.text}
                    </Text>
                  </CardBody>
                </Card>

                <Card
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                >
                  <CardBody>
                    <Box
                      bgColor="gray.200"
                      h="40px"
                      w="40px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="10px"
                    >
                      <FaDatabase size={32} color="#0c1F41" />
                    </Box>
                    <Heading size="md" fontWeight="bold" color="primary.500">
                      {translations.storageOfWealth.heading}
                    </Heading>
                    <Text color="primary.600">
                      {translations.storageOfWealth.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
          </Grid>         
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          marginBottom="2rem"
          bgColor="white"
          paddingBottom="2rem"
        >
          <Heading
            size="xl"
            textAlign="center"
            marginTop="2rem"
            color="primary.600"
          >
            {translations.what_our_investors_say}
          </Heading>
          <br />
          <Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "1rem" }}
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={5}
            marginBottom="2rem"
          >
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="A. Katrina"
                  text="Investing with Alliance Capital Dubai
                  has been exceptional. Their transparent
                  and professional approach, coupled
                  with strategic insights, has surpassed
                  my expectations. The guaranteed
                  returns, coupled with personalized
                  service, make them a standout choice
                  for anyone seeking a reliable and
                  rewarding investment partner. I highly
                  recommend Alliance Capital for their
                  commitment to excellence and
                  consistent results.
                  "
                  investering={150000}
                />

              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="E. Aboulame"
                  text="So, I jumped into the investment game
                  with Alliance Capital Dubai, and it's
                  been pretty sweet. No fancy jargon—
                  just clear communication and solid
                  returns that make me smile. They know
                  their stuff, keep things interesting, and,
                  most importantly, deliver on the
                  promises. Thumbs up from this investor!
                  🚀
                  
                  "
                  investering={700000}
                />
              </Zoom>
            </GridItem>
            {/* <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="N. Plessen"
                  text="Never thught that there will be such
                  thing as a guaranteed roi. Well Milad
                  did a good job with Alliance Capital. He
                  knows what he is doing and he knows
                  how to create a win - win situation.
                  Keep up the great work! Looking
                  forward to keep investing with you
                  guys."
                  investering={100000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="H. Hamza"
                  text="Choosing Alliance Capital Dubai for my
                  investment was a wise move. The
                  team's transparent and strategic
                  approach has not only ensured steady
                  returns but has also instilled confidence
                  in their expertise. Their commitment to
                  professionalism and client satisfaction
                  sets them apart. In summary, a solid
                  choice for anyone looking for a
                  trustworthy partner in their investment
                  journey."
                  investering={75000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="O. Koniu"
                  text="Choosing Alliance Capital Dubai for my
                  investment has been a journey marked
                  by strategic brilliance. Their
                  commitment to transparency and
                  meticulous financial strategies has
                  consistently translated into noteworthy
                  returns. The team's professionalism
                  shines through, making Alliance Capital
                  a reliable choice for those seeking a
                  formal and dependable investment
                  partner.
                  "
                  investering={550000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="A. van Leer"
                  text="Venturing into the investment domain
                  with Alliance Capital Dubai has been
                  nothing short of a strategic triumph.
                  The team's unwavering commitment to
                  transparency and a systematic
                  approach to financial management
                  have set the stage for a highly
                  satisfactory investment journey.
                  The returns generated are a testament
                  to the astute market navigation
                  orchestrated by Alliance Capital.
                  Consistency in performance has
                  instilled a sense of confidence,
                  surpassing my initial expectations."
                  investering={150000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="O. Khalilzadeh"
                  text="I decided to roll the dice with Alliance
                  Capital Dubai for my investments, and
                  let me tell you, it's been a ride worth
                  talking about. These folks got a knack
                  for making the financial game look like
                  a walk in the park.
                  Returns? Oh, they're not just good;
                  they're the kind that puts a grin on your
                  face. Alliance Capital’s way of doing
                  things is refreshing—no fancy jargon,
                  just straightforward talk that even a
                  finance newbie like me can vibe with.
                  The crew? Super chill. They've got that
                  cool mix of professionalism and
                  friendliness."
                  investering={825000}
                />
              </Zoom>
            </GridItem>
            <GridItem>
              <Zoom>
                <ReviewCard
                  investorName="O. Conor"
                  text="Choosing Alliance Capital Dubai for my
                  investment proved to be a prudent
                  decision. Their commitment to
                  transparency and strategic financial
                  practices set the stage for a reliable
                  and rewarding investment journey.
                  The Returns: Impressive and Consistent
                  What stands out most is the impressive
                  and consistent returns. Alliance
                  Capital's approach to navigating
                  market dynamics has translated into
                  financial success, exceeding my initial
                  expectations.
                  "
                  investering={450000}
                />
              </Zoom>
            </GridItem> */}
          </Grid>
          <Link href="/testimonials">
            <Button
              borderColor="primary.500"
              color="primary.600"
              marginRight="1rem"
              size="lg"
              variant="outline"
            >
              {translations.what_our_investorts_say_button}
            </Button>
          </Link>
        </Flex>
        <Flex
          bgGradient="linear(to-tr, #CEB688, #0c1F41)"
          flexDirection="column"
          alignItems="center"
          gap="15px"
          padding="4rem"
        >
          <Heading size="xl" color="white" marginTop="2rem">
            {translations.call_to_action_title}
          </Heading>
          <Text fontSize="lg" color="white">
            {translations.call_to_action_subtitle}
          </Text>
          <Link as="a" href="/start-to-invest">
            <Button marginBottom="2rem" size="lg">
              {translations.call_to_action_button}
            </Button>
          </Link>
        </Flex>

        <Footer />
      </Fade>
    </>
  );
};
