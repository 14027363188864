import {
  Grid,
  Image,
  Flex,
  Heading,
  GridItem,
  Button,
  Link,
  Card,
  Text,
} from "@chakra-ui/react";
import imggreen from "./assets/dubai-green.jpg";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";
import { useLocalization } from "./context/LocalizationContext";

const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");


ReactGA.send({
  hitType: "pageview",
  page: "/about-us",
  title: `About us | Alliance Ventura`,
});

export const About = () => {
  const { translations } = useLocalization();
  document.title = `${translations.about_us} | Alliance Ventura`;


  return (
    <>
      <Fade>
        <Heading textAlign="center" size="md" color="primary.500" marginTop="2rem">
          {translations.about_alliance}
        </Heading>

        <Heading textAlign="center" size="lg" color="primary.600" margin="1rem">
          {translations.about_alliance_text}

        </Heading>
        <Flex justifyContent="center" height={{ base: "auto", md: "80vh" }}>
          <Zoom>
            <Image
              src={require(`./assets/about-us-hero-${translations.lang_code}.png`)}
              alt="About us Hero Image"
              borderTopRadius="lg"
              loading="lazy"
              maxW="100%"
              maxH="100%"
            />
          </Zoom>
        </Flex>

        <Grid
          maxWidth="1200px"
          mx="auto"
          p={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} // Three equal-width columns on medium and larger screens
          gap={5} // Gap between grid items
        >
          <GridItem display="flex" justifyContent="center" alignItems="center">
            <Heading size="xl" color="primary.500" fontWeight="bold">
              {translations.about_ready}
            </Heading>
          </GridItem>
          <GridItem>
            {translations.about_ready_text1}<br />
            <br />
            {translations.about_ready_text2}
          </GridItem>
        </Grid>

        <Grid
          maxWidth="1200px"
          mx="auto"
          p={{ base: "1rem", md: "2rem" }}
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
          gap={5}
        >
          <GridItem>
            <Card p="1rem">
              <Heading color="primary.500">{translations.valute_sign} 76 mln +</Heading>
              <Text>{translations.targets_portfolio}</Text>
            </Card>
          </GridItem>
          <GridItem>
            <Card p="1rem">
              <Heading color="primary.500">{translations.valute_sign} 4 mln +</Heading>
              <Text>{translations.targets_payouts}</Text>
            </Card>
          </GridItem>
          <GridItem>
            <Card p="1rem">
              <Heading color="primary.500">150 +</Heading>
              <Text>{translations.targets_properties}</Text>
            </Card>
          </GridItem>
        </Grid>
        <Grid
          maxWidth="1200px"
          mx="auto"
          p={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} // Three equal-width columns on medium and larger screens
          gap={5} // Gap between grid items
        >
          <GridItem alignItems="center" justifyContent="center">
            <Zoom>
              <Image
                src={imggreen}
                alt="Grow with Alliance Capital"
                borderTopRadius="lg"
                borderBottomRadius="lg"
                loading="lazy"
                maxW="100%" // Make sure the image doesn't exceed its container width
                maxH="100%" // Make sure the image doesn't exceed its container height
              />
            </Zoom>
          </GridItem>
          <GridItem>
            <br />
            <Heading
              size="xl"
              color="primary.500"
              fontWeight="bold"
              marginTop="10px"
              marginBottom="10px"
            >
              {translations.grow_with_alliance}
            </Heading>
            <br />
            {translations.about_us_text}<br /><br />
            {translations.about_us_text2}<br /><br />
            {translations.about_us_text3}<br /><br />
            {translations.about_us_text4}<br /><br />
            <Link href="/contact">
              <Button
                borderColor="primary.500"
                color="primary.600"
                size="lg"
                variant="outline"
              >
                {translations.contact_us}
              </Button>
            </Link>

          </GridItem>
        </Grid>
        <Footer />
      </Fade>
    </>
  );
};
