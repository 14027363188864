import {
  Box,
  Flex,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";

const Fade = require("react-reveal/Fade");

ReactGA.send({
  hitType: "pageview",
  page: "/privacy-beleid",
  title: "Privacy Beleid | Alliance Capital",
});

export const PrivacyPolicy = () => {
  document.title = "Privacy Beleid | Alliance Capital";

  return (
    <>
      <Fade>
        <Box maxW="1200px" m="0 auto" p="4" marginY="3rem">
          <Heading as="h1" fontSize="xl" mb="4">
            Privacybeleid voor Alliance Capital
          </Heading>
          <Text>
            <strong>Laatst bijgewerkt:</strong> [Datum van de laatste update]
          </Text>

          <Text mt="4">
            Dit Privacybeleid beschrijft hoe Alliance Capital ("Wij", "Ons" of
            "Onze") uw persoonlijke gegevens verzamelt, gebruikt en deelt
            wanneer u onze website [URL van de website] ("Website") bezoekt.
            Lees dit Privacybeleid zorgvuldig door voordat u onze Website
            gebruikt.
          </Text>

          <Text mt="4">
            <strong>1. Verzamelde Informatie</strong>
          </Text>
          <Text mt="2">
            <strong>1.1. Persoonlijke Identificatie Informatie:</strong> We
            kunnen persoonlijke identificatie-informatie verzamelen, zoals uw
            naam, e-mailadres, telefoonnummer en andere informatie die u
            vrijwillig aan ons verstrekt wanneer u zich registreert, zich
            aanmeldt voor onze nieuwsbrief, contact met ons opneemt of deelneemt
            aan enquêtes.
          </Text>
          <Text mt="2">
            <strong>1.2. Niet-persoonlijke Informatie:</strong> We kunnen
            niet-persoonlijke informatie verzamelen, zoals uw IP-adres,
            browsertype, het besturingssysteem van uw computer en andere
            technische informatie wanneer u onze Website bezoekt.
          </Text>

          <Text mt="4">
            <strong>2. Gebruik van Informatie</strong>
          </Text>
          <Text mt="2">
            <strong>2.1. Verbetering van onze Diensten:</strong> We gebruiken de
            verzamelde informatie om onze diensten te verbeteren, uw ervaring op
            onze Website te personaliseren en om met u te communiceren.
          </Text>
          <Text mt="2">
            <strong>2.2. Communicatie:</strong> We kunnen de informatie
            gebruiken om u updates, nieuwsbrieven, marketingmateriaal en andere
            informatie te sturen die relevant is voor u. U kunt zich te allen
            tijde afmelden voor dergelijke communicatie.
          </Text>

          <Text mt="4">
            <strong>3. Delen van Informatie</strong>
          </Text>
          <Text mt="2">
            <strong>3.1. Derden:</strong> We delen uw persoonlijke informatie
            niet met derden zonder uw toestemming, behalve wanneer dit nodig is
            voor het verlenen van de door u gevraagde diensten of om te voldoen
            aan wettelijke verplichtingen.
          </Text>

          <Text mt="4">
            <strong>4. Cookies</strong>
          </Text>
          <Text mt="2">
            <strong>4.1. Cookies:</strong> We maken gebruik van cookies om
            informatie te verzamelen en uw online-ervaring te verbeteren. U kunt
            het gebruik van cookies uitschakelen in uw browserinstellingen.
          </Text>

          <Text mt="4">
            <strong>5. Toegang tot uw Gegevens</strong>
          </Text>
          <Text mt="2">
            <strong>5.1. Rechten:</strong> U heeft het recht om uw persoonlijke
            gegevens in te zien, te corrigeren, te verwijderen of over te
            dragen. Neem contact met ons op om uw verzoek in te dienen.
          </Text>

          <Text mt="4">
            <strong>6. Wijzigingen in het Privacybeleid</strong>
          </Text>
          <Text mt="2">
            <strong>6.1. Wijzigingen:</strong> We behouden ons het recht voor om
            dit Privacybeleid op elk moment te wijzigen. De datum van de laatste
            update wordt bovenaan het beleid vermeld.
          </Text>

          <Text mt="4">
            <strong>7. Contactinformatie</strong>
          </Text>
          <Text mt="2">
            <strong>7.1. Vragen:</strong> Als u vragen hebt over ons
            Privacybeleid, neem dan contact met ons op via info@alliance-capital.nl
          </Text>

          <Text mt="4">
            Bedankt voor het vertrouwen in Alliance Capital. We waarderen uw
            privacy en streven ernaar uw gegevens te beschermen.
          </Text>
        </Box>
        <Footer />
      </Fade>
    </>
  );
};
