import React, { useState, ChangeEvent, FormEvent } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useLocalization } from "../context/LocalizationContext";

interface FormData {
  name: string;
  email: string;
  message: string;
}

const ContactForm: React.FC = () => {
  const { translations } = useLocalization();

  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });

  const toast = useToast();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // Here, you can add code to handle the form submission,
    // such as sending the data to a server or displaying a success message.
    // For this example, we'll just display a success toast message.
    toast({
      title: "Message sent",
      description: "Your message has been sent successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    // Reset the form fields
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <FormControl id="name" mb={4}>
          <FormLabel>{translations.contactform_fullname}</FormLabel>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl id="email" mb={4}>
          <FormLabel>{translations.contactform_email}</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl id="message" mb={6}>
          <FormLabel>{translations.contactform_message}</FormLabel>
          <Textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </FormControl>
        <Button type="submit" color="white" backgroundColor="primary.500">
        {translations.contactform_send}
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
