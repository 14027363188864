import {
  Box,
  Flex,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";

const Fade = require("react-reveal/Fade");

ReactGA.send({
  hitType: "pageview",
  page: "/algemene-voorwaarden",
  title: "Algemene Voorwaarden | Alliance Capital",
});

export const TermsOfUse = () => {
  document.title = "Algemene Voorwaarden | Alliance Capital";

  return (
    <>
      <Fade>
        <Box maxW="1200px" m="0 auto" p="4" marginY="3rem">
          <Heading as="h1" fontSize="xl" mb="4">
            Gebruiksvoorwaarden voor Alliance Capital (alliance-capital.nl)
          </Heading>
          <Text>
            <strong>Laatst bijgewerkt:</strong> 13/10/2023
          </Text>

          <Text mt="4">
            Welkom bij Alliance Capital! Deze gebruiksvoorwaarden
            ("Voorwaarden") regelen uw gebruik van de website
            alliance-capital.nl ("Website") en de diensten die worden aangeboden
            door Alliance Capital ("Wij", "Ons" of "Onze"). Door deze Website te
            bezoeken en gebruik te maken van onze diensten, gaat u akkoord met
            deze Voorwaarden. Lees ze zorgvuldig door voordat u doorgaat met het
            gebruik van de Website.
          </Text>

          <Text mt="4">
            <strong>1. Toegang tot de Website</strong>
          </Text>
          <Text mt="2">
            1.1. U moet minimaal 18 jaar oud zijn om onze Website te gebruiken.
          </Text>
          <Text mt="2">
            1.2. U gaat akkoord om de Website alleen te gebruiken voor wettige
            doeleinden en in overeenstemming met deze Voorwaarden.
          </Text>
          <Text mt="2">
            1.3. We behouden ons het recht voor om uw toegang tot de Website op
            elk moment en om welke reden dan ook te weigeren.
          </Text>

          <Text mt="4">
            <strong>2. Informatie en Inhoud</strong>
          </Text>
          <Text mt="2">
            2.1. Alle informatie en inhoud op onze Website zijn alleen voor
            informatieve doeleinden en vormen geen financieel, beleggings- of
            juridisch advies.
          </Text>
          <Text mt="2">
            2.2. Wij garanderen niet de nauwkeurigheid of volledigheid van de
            informatie op de Website en zijn niet verantwoordelijk voor
            eventuele fouten of weglatingen.
          </Text>

          <Text mt="4">
            <strong>3. Beleggen in Dubai</strong>
          </Text>
          <Text mt="2">
            3.1. Het gebruik van onze diensten om te investeren in Dubai
            impliceert financiële risico's. U dient advies in te winnen bij een
            financieel adviseur voordat u investeringsbeslissingen neemt.
          </Text>
          <Text mt="2">
            3.2. Wij zijn geen financieel adviseurs en dragen geen
            verantwoordelijkheid voor uw investeringsbeslissingen.
          </Text>

          <Text mt="4">
            <strong>4. Gebruikersaccounts</strong>
          </Text>
          <Text mt="2">
            4.1. Om toegang te krijgen tot bepaalde delen van de Website, kunt u
            worden gevraagd een gebruikersaccount aan te maken. U bent
            verantwoordelijk voor de vertrouwelijkheid van uw accountgegevens en
            wachtwoord.
          </Text>
          <Text mt="2">
            4.2. U bent verantwoordelijk voor alle activiteiten die plaatsvinden
            onder uw account.
          </Text>

          <Text mt="4">
            <strong>5. Intellectueel Eigendom</strong>
          </Text>
          <Text mt="2">
            5.1. Alle inhoud op de Website, inclusief maar niet beperkt tot
            tekst, afbeeldingen, logo's en handelsmerken, zijn eigendom van
            Alliance Capital en zijn beschermd door auteursrechten en andere
            intellectuele eigendomsrechten.
          </Text>
          <Text mt="2">
            5.2. U mag de inhoud van de Website niet kopiëren, verspreiden,
            wijzigen, doorverkopen of gebruiken voor commerciële doeleinden
            zonder schriftelijke toestemming van Alliance Capital.
          </Text>

          {/* Voeg de overige secties van de voorwaarden op dezelfde manier in als hierboven */}

          <Text mt="4">
            <strong>6. Privacy</strong>
          </Text>
          <Text mt="2">
            6.1. Ons Privacybeleid regelt hoe we uw persoonlijke gegevens
            verzamelen, gebruiken en delen. Door gebruik te maken van onze
            Website, gaat u akkoord met ons Privacybeleid.
          </Text>

          <Text mt="4">
            <strong>7. Beperking van Aansprakelijkheid</strong>
          </Text>
          <Text mt="2">
            7.1. Alliance Capital is niet aansprakelijk voor enige directe,
            indirecte, incidentele, gevolg- of speciale schade die voortvloeit
            uit uw gebruik van de Website of de diensten die we aanbieden.
          </Text>

          <Text mt="4">
            <strong>8. Wijzigingen in de Voorwaarden</strong>
          </Text>
          <Text mt="2">
            8.1. We behouden ons het recht voor om deze Voorwaarden op elk
            moment te wijzigen. We zullen de bijgewerkte versie van de
            Voorwaarden op de Website plaatsen.
          </Text>

          <Text mt="4">
            <strong>9. Toepasselijk Recht en Geschillen</strong>
          </Text>
          <Text mt="2">
            9.1. Deze Voorwaarden worden beheerst door en geïnterpreteerd in
            overeenstemming met de wetten van Nederland. Alle geschillen die
            voortvloeien uit of verband houden met deze Voorwaarden zullen
            worden voorgelegd aan de bevoegde rechtbanken in Nederland.
          </Text>

          <Text mt="4">
            <strong>10. Contactinformatie</strong>
          </Text>
          <Text mt="2">
            10.1. Als u vragen of opmerkingen heeft over deze Voorwaarden, neem
            dan contact met ons op via info@alliance-capital.nl
          </Text>

          <Text mt="4">
            Door gebruik te maken van de Website, bevestigt u dat u deze
            Voorwaarden hebt gelezen en begrepen en dat u akkoord gaat met alle
            bepalingen en voorwaarden die hierin zijn uiteengezet.
          </Text>

          <Text mt="4">Bedankt voor het gebruik van Alliance Capital!</Text>

        </Box>
        <Footer />
      </Fade>
    </>
  );
};
