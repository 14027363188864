import { Grid } from "@chakra-ui/react";
import HeroTitle from "./components/HeroTitle";
import Footer from "./components/Footer";

export const NotFound = () => {
  document.title = "404 - Page not found | Alliance Capital";

  return (
    <>
      <Grid
        maxWidth="1200px"
        mx="auto"
        justifyContent="center"
        alignItems="center"
        p={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
      >
        <HeroTitle
          title="Page not found"
          subtitle="That page you are looking for may have been deleted or moved."
        />
      </Grid>
      <Footer />
    </>
  );
};
