import {
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Text,
  Image,
  Heading,
  Card,
  CardBody,
  Stack,
  Box,
  Icon,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MdFormatQuote } from 'react-icons/md';
const Fade = require("react-reveal/Fade");



interface ReviewCardProps {
  investorName: string;
  text: string;
  investering: number;
}


const ReviewCard: React.FC<ReviewCardProps> = ({
  investorName,
  investering,
  text
}) => {

  const formattedInvestering = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    compactDisplay: "short",
  }).format(investering);

  const formattedInvesteringWithoutDecimal = formattedInvestering.replace(/\.00$/, '');


  return (
    <Fade>
      <Link
        as="a"
        textDecoration="none"
        _hover={{ textDecoration: "none" }}
      >
        <Card
          maxWidth="100%"
          minHeight={"450px"}
          justifyContent={"center"}
          alignItems={"center"}
          overflowX="auto"
          transition="transform 0.2s ease" // Add transition for smooth animation
          _hover={{
            transform: "translateY(-5px)", // Move the box 4 pixels up on hover
          }}
          cursor="pointer"

        >


          <CardBody>
            <Stack mt="6" spacing="4">

              <Box
                w="60px"
                h="50px"
                bg="primary.600"
                borderRadius="0% 50% 50% 0% / 50% 0% 100% 50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginBottom={5}
              //transform="rotate(45deg)"
              >
                <Icon as={MdFormatQuote} color="primary.500" boxSize={12} />
              </Box>
              <Text>
                {text}
              </Text> <Box
                background="white"
                borderRadius="lg"
                padding="0.5rem"
                inlineSize="max-content" // Set the width to the content's size
                minInlineSize="max-content"
              >
                <Flex position={"absolute"} bottom={5}>
  <Text marginLeft="0.5rem" fontSize="small" color="black">
    <strong style={{ fontSize: 18 }}>{investorName}</strong> <br /> 
    <Box
      background="primary.500"
      borderRadius="lg"
      padding="0.5rem"
      inlineSize="max-content" // Set the width to the content's size
      minInlineSize="max-content"
    >
      Invested <strong>{formattedInvesteringWithoutDecimal}</strong>
    </Box>
  </Text>
</Flex>
              </Box>

            </Stack>
          </CardBody>
        </Card>
      </Link>
    </Fade>
  );
};

export default ReviewCard;
