import React from "react";
import { Text, Heading, Box } from "@chakra-ui/react";

interface HeroTitleProps {
  title: string;
  subtitle?: string;
}

const HeroTitle: React.FC<HeroTitleProps> = ({ title, subtitle }) => {
  return (
    <Box
      maxWidth="720px"
      mx="auto"
      p="1rem"
      display="flex"
      flexDirection="column"
      alignItems="center" // Center vertically
      justifyContent="center" // Center horizontally
    >
      <Heading color="primary.500">{title}</Heading>
      {subtitle ? (
        <Text
          marginTop="1rem"
          fontWeight="semibold"
          color="black"
          align="center"
        >
          {subtitle}
        </Text>
      ) : null}
    </Box>
  );
};

export default HeroTitle;
