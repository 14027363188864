import {
  Grid,
  Image,
  Flex,
  Heading,
  GridItem,
  Card,
  Text,
  Box,
  CardBody,
  VStack,
} from "@chakra-ui/react";
import WorldMapSVG from "./assets/world-map.png";
import Footer from "./components/Footer";
import { FaChartLine, FaCheckDouble, FaDollarSign, FaMapSigns, FaUniversalAccess } from "react-icons/fa";
import {
  MdCardGiftcard,
  MdPermContactCalendar,
  MdAirplanemodeActive,
} from "react-icons/md";
import difcLogo from "./assets/difc-logo.png";
import goldenVisaImg from "./assets/golden-visa.jpg";
import miladImg from "./assets/milad.png";
import quoteImgStart from "./assets/quote-mark-start.png";
import quoteImgEnd from "./assets/quote-mark-end.png";
import CustomAccordion from "./components/CustomAccordion";
import ReactGA from "react-ga4";
import { useLocalization } from "./context/LocalizationContext";

const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");

ReactGA.send({
  hitType: "pageview",
  page: "/golden-visa",
  title: "UAE Golden Visa | Alliance Capital",
});

export const GoldenVisa = () => {
  document.title = "UAE Golden Visa | Alliance Capital";
  const { translations } = useLocalization();

  return (
    <>
      <Fade>
        <Flex
          backgroundImage={WorldMapSVG}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundBlendMode="soft-light"
        >
          <Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
            templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }} // Three equal-width columns on medium and larger screens
            gap={5} // Gap between grid items
          >
            <GridItem
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Flex flexDirection="column">
                <Heading
                  size="md"
                  color="primary.500"
                  fontWeight="bold"
                  textAlign="center"
                  marginTop="1rem"
                >
                  {translations.golden_visa_heading_text}
                </Heading>
                <Heading
                  size="xl"
                  color="primary.600"
                  fontWeight="bold"
                  marginBottom="1rem"
                  textAlign="center"
                  marginTop="1rem"
                >
                  {translations.golden_visa_heading_subtitle}
                </Heading>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex
                justifyContent="center"
                height={{ base: "auto", md: "60vh" }}
              >
                <Zoom>
                  <Image
                    src={require(`./assets/about-us-hero-${translations.lang_code}.png`)}
                    alt="About us Hero Image"
                    borderTopRadius="lg"
                    loading="lazy"
                    maxW="100%"
                    maxH="100%"
                  />
                </Zoom>
              </Flex>
            </GridItem>
          </Grid>
        </Flex>

        <Flex
          backgroundColor="white"
          flexDirection="column"
          alignItems="center"
          marginBottom="1rem"
          p="1rem"
        >
          <Heading size="lg" textAlign="center" color="primary.500">
          {translations.golden_visa_whatis}
          </Heading>
          <Text
            textAlign="center"
            maxW="800px"
            marginTop="1rem"
            color="primary.600"
          >
           {translations.golden_visa_whatis_text}
          </Text>
          <Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "2rem" }}
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            gap={5}
            marginBottom="2rem"
          >
            <GridItem>
              <Flex justifyContent="center">
                <Zoom>
                  <Image
                    src={goldenVisaImg}
                    alt="About us Hero Image"
                    objectFit="cover"
                    loading="lazy"
                    height="80vh"
                    borderRadius="md"
                  />
                </Zoom>
              </Flex>
            </GridItem>

            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <VStack gap="15px">
                  <Card
                    maxWidth="100%"
                    overflowX="auto"
                    transition="transform 0.2s ease"
                    _hover={{
                      transform: "translateY(-5px)",
                    }}
                    cursor="pointer"
                  >
                    <CardBody>
                      <Heading
                        size="md"
                        fontWeight="bold"
                        color="primary.600"
                        marginBottom="10px"
                      >
                        {translations.longTermResidenceRights.heading}
                      </Heading>
                      <Text color="primary.600">
                        {translations.longTermResidenceRights.text}
                      </Text>
                    </CardBody>
                  </Card>
                  <Card
                    maxWidth="100%"
                    overflowX="auto"
                    transition="transform 0.2s ease"
                    _hover={{
                      transform: "translateY(-5px)",
                    }}
                    cursor="pointer"
                  >
                    <CardBody>
                      <Heading
                        size="md"
                        fontWeight="bold"
                        color="primary.600"
                        marginBottom="10px"
                      >
                        {translations.bringWholeFamily.heading}
                      </Heading>
                      <Text color="primary.600">
                        {translations.bringWholeFamily.text}
                      </Text>
                    </CardBody>
                  </Card>
                  <Card
                    maxWidth="100%"
                    overflowX="auto"
                    transition="transform 0.2s ease"
                    _hover={{
                      transform: "translateY(-5px)",
                    }}
                    cursor="pointer"
                  >
                    <CardBody>
                      <Heading
                        size="md"
                        fontWeight="bold"
                        color="primary.600"
                        marginBottom="10px"
                      >
                        {translations.travelWithoutRestrictions.heading}
                      </Heading>
                      <Text color="primary.600">
                        {translations.travelWithoutRestrictions.text}
                      </Text>
                    </CardBody>
                  </Card>
                  <Card
                    maxWidth="100%"
                    overflowX="auto"
                    transition="transform 0.2s ease"
                    _hover={{
                      transform: "translateY(-5px)",
                    }}
                    cursor="pointer"
                  >
                    <CardBody>
                      <Heading
                        size="md"
                        fontWeight="bold"
                        color="primary.600"
                        marginBottom="10px"
                      >
                        {translations.enterFastestGrowingMarket.heading}
                      </Heading>
                      <Text color="primary.600">
                        {translations.enterFastestGrowingMarket.text}
                      </Text>
                    </CardBody>
                  </Card>
                </VStack>
              </Zoom>
            </GridItem>
          </Grid>
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="center"
          bgColor="gray.100"
          paddingBottom="2rem"
          p="1rem"
        >
          <Heading
            size="xl"
            textAlign="center"
            marginTop="2rem"
            marginBottom="2rem"
            color="primary.600"
          >
           {translations.golden_visa_smartways_title}
          </Heading>

          <Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "1rem" }}
            templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }}
            gap={5}
            marginBottom="2rem"
          >
            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <Card
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                  minH="375px"
                >
                  <CardBody>
                    <Box
                      bgColor="gray.200"
                      h="40px"
                      w="40px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="10px"
                    >
                      <FaUniversalAccess size={32} color="#0c1F41" />
                    </Box>
                    <Heading size="md" fontWeight="bold" color="primary.500" marginBottom="1rem">
                      {translations.accessibility.heading}
                    </Heading>
                    <Text color="primary.600">
                      {translations.accessibility.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <Card
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                  minH="375px"
                >
                  <CardBody>
                    <Box
                      bgColor="gray.200"
                      h="40px"
                      w="40px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="20px"
                    >
                      <FaMapSigns size={32} color="#0c1F41" />
                    </Box>
                    <Heading size="md" fontWeight="bold" color="primary.500" marginBottom="1rem">
                      {translations.diversification.heading}
                    </Heading>
                    <Text color="primary.600">
                      {translations.diversification.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <Card
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                  minH="375px"
                >
                  <CardBody>
                    <Box
                      bgColor="gray.200"
                      h="40px"
                      w="40px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="20px"
                    >
                      <FaChartLine size={32} color="#0c1F41" />
                    </Box>
                    <Heading size="md" fontWeight="bold" color="primary.500">
                      {translations.investWithoutWorries.heading}
                    </Heading>
                    <Text color="primary.600">
                      {translations.investWithoutWorries.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <Card
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                  minH="375px"
                >
                  <CardBody>
                    <Box
                      bgColor="gray.200"
                      h="40px"
                      w="40px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="20px"
                    >
                      <FaCheckDouble size={32} color="#0c1F41" />
                    </Box>
                    <Heading size="md" fontWeight="bold" color="primary.500">
                      {translations.bestInvestmentDeals.heading}
                    </Heading>
                    <Text color="primary.600">
                      {translations.bestInvestmentDeals.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
          </Grid>         
        </Flex>

        {/* <Flex
          bgColor="#071327"
          flexDirection="column"
          alignItems="center"
          gap="15px"
        // padding="4rem"
        >
          <Heading
            size="xl"
            color="white"
            marginTop="3rem"
            textAlign="center"
            paddingX="2rem"
          >
            {translations.platinumMembershipHeading}
          </Heading>
          <Text
            fontSize="lg"
            color="white"
            maxWidth="800px"
            textAlign="center"
            paddingX="2rem"
          >
            {translations.platinumMembershipText}
          </Text>
          <Grid
            maxWidth="1200px"
            mx="auto"
            p={{ base: "1rem", md: "1rem" }}
            templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }}
            gap={5}
            marginBottom="2rem"
          >
            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <Card
                  backgroundColor="#0c1f41"
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                >
                  <CardBody>
                    <MdPermContactCalendar size={38} color="#CEB688" />
                    <Heading
                      size="lg"
                      fontWeight="bold"
                      color="white"
                      marginTop="3rem"
                    >
                      {translations.referral.heading}
                    </Heading>
                    <Text color="white" size="lg">
                      {translations.referral.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <Card
                  backgroundColor="#0c1f41"
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                >
                  <CardBody>
                    <FaDollarSign size={38} color="#CEB688" />
                    <Heading
                      size="lg"
                      fontWeight="bold"
                      color="white"
                      marginTop="3rem"
                    >
                      {translations.cashback.heading}
                    </Heading>
                    <Text color="white" size="lg">
                      {translations.cashback.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <Card
                  backgroundColor="#0c1f41"
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                >
                  <CardBody>
                    <MdCardGiftcard size={38} color="#CEB688" />
                    <Heading
                      size="lg"
                      fontWeight="bold"
                      color="white"
                      marginTop="3rem"
                    >
                      {translations.welcomePack.heading}
                    </Heading>
                    <Text color="white" size="lg">
                      {translations.welcomePack.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
            <GridItem marginTop={{ base: "1rem", md: "1rem" }}>
              <Zoom>
                <Card
                  backgroundColor="#0c1f41"
                  maxWidth="100%"
                  overflowX="auto"
                  transition="transform 0.2s ease"
                  _hover={{
                    transform: "translateY(-5px)",
                  }}
                  cursor="pointer"
                  marginBottom="30px"
                >
                  <CardBody>
                    <MdAirplanemodeActive size={38} color="#CEB688" />
                    <Heading
                      size="lg"
                      fontWeight="bold"
                      color="white"
                      marginTop="3rem"
                    >
                      {translations.stayInDXB.heading}
                    </Heading>
                    <Text color="white" fontSize="14px">
                      {translations.stayInDXB.text}
                    </Text>
                  </CardBody>
                </Card>
              </Zoom>
            </GridItem>
          </Grid>
        </Flex> */}
        <Flex
          bgColor="gray.100"
          flexDirection="column"
          alignItems="center"
          position="relative"
        >
          <VStack maxW="1200px" marginY="4rem">
            <Image src={miladImg} width="175px" height="175px" />
            <Text fontWeight="bold">{translations.founderName}</Text>
            <Text marginTop="-1rem">{translations.founderTitle}</Text>
            <Box position="relative">
              <Image
                src={quoteImgStart}
                width="3rem"
                position="absolute"
                left="0"
                top="0"
              />
              <Text textAlign="center" fontSize="lg" margin="2rem">
                {translations.founderQuote}
              </Text>
              <Image
                src={quoteImgEnd}
                width="3rem"
                position="absolute"
                right="0"
                bottom="0"
              />
            </Box>
          </VStack>
        </Flex>

        <Flex bgColor="white" flexDirection="column" alignItems="center">
          <VStack maxW="1200px" marginY="4rem">
            <Heading size="xl" textAlign="center" color="primary.600">
              {translations.faqHeading}
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="primary.600"
              marginBottom="3rem"
              maxW="900px"
            >
              {translations.faqDescription}
            </Text>
            <CustomAccordion />
          </VStack>
        </Flex>

        <Footer />
      </Fade>
    </>
  );
};
