import {
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";

const Fade = require("react-reveal/Fade");

ReactGA.send({
  hitType: "pageview",
  page: "/key-risks",
  title: "Key Risks | Alliance Capital",
});

export const KeyRisks = () => {
  document.title = "Key Risks | Alliance Capital";

  return (
    <>
      <Fade>
        <Flex justifyContent="center" alignItems="center" marginTop="2rem">
          <Stack maxWidth="1200px">
            <Heading size="lg" marginBottom="1rem">
              Key Risks
            </Heading>
            <Heading size="md">No guarantee</Heading>
            <Text marginBottom="1rem">
              Past performance is no guarantee of future performance
              returns. Some historical performance, expected returns
              or projected figures may not lead to actual ones
              future performance. Your capital is at risk and is not
              guaranteed.
            </Text>
            <Heading size="md">Maturity and liquidity</Heading>
            <Text marginBottom="1rem">
              The investments offered by Alliance Capital are intended to
              during the entire investment period of 5 years
              arrested and not recorded. We offer a Secondary
              Transfer Facility (referred to as the “Exit Window”) on the
              platform where you can make your investment before the end of the recommended
              can sell in a timely manner. A Secondary Transfer Facility on
              Alliance Capital is only for two weeks at a time, twice a year
              available. Therefore, you may not be able to get out of your pregnancy early
              investment steps, and as such it can be illiquid
              be an investment. Do not make an investment with Alliance Capital if
              your intention is not to make the investment over the recommended period
              term to be adhered to. Even with a Secondary
              Transfer facility available on Alliance Capital, still there
              There is always no guarantee that you will be able to sell your investment.
            </Text>
            <Heading size="md">Risk warning</Heading>
            <Text marginBottom="1rem">
              Real estate investments are speculative. Investors
              through Alliance Capital will not directly own
              the real estate; instead, they will have a stake in it
              a privately owned special purpose vehicle that owns the property. The
              value of your investments may experience losses, and expected ones
              returns may not materialize as
              predicted. Additionally, you may experience delays in the
              receipt of rental income or it may be difficult to do so
              to sell real estate at the end of the investment period. In
              In some cases there may be government restrictions
              regarding the sale of real estate to foreign owners,
              which may limit the number of potential buyers. Alliance Capital
              offers investments that differ materially from direct,
              full ownership of real estate, investments in real estate funds,
              REITs or other securities or interests. Being like Alliance Capital
              cease operations, you may experience losses, incur costs and/or
              experience delays in payment. The use of credit
              or borrowed money to invest through Alliance Capital brings a
              carries greater risk because even if your investment in
              value drops, you still have to pay your
              meet repayment obligations.
            </Text>
            <Heading size="md">No advice</Heading>
            <Text marginBottom="3rem">
              Alliance Capital operates a real estate investment platform and
              manages privately owned vehicles on behalf of investors
              are real estate. Alliance Capital is not
              investment advisor and not a real estate manager. We don't give any
              investment or other advice. Investors must do their own due
              do their due diligence before investing and/or becoming an independent
              consult a financial advisor. When evaluating
              investment opportunities, we use data from
              third parties that we consider reliable. We can verify the accuracy or
              However, we cannot guarantee the completeness of such data.
            </Text>
          </Stack>
        </Flex>
        <Footer />
      </Fade>
    </>
  );
};
