import {
  Box,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  Text,
  VStack,
  Textarea,
  Input,
  Select,
  HStack,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormData } from "../constants/types";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { MdEuroSymbol } from "react-icons/md";
import { ChakraPhone } from "./ChakraPhone";
import { FaDollarSign } from "react-icons/fa";
import { useLocalization } from "../context/LocalizationContext";

interface CustomStepperProps {
  onFormSubmit: (formData: FormData) => void;
  resetFlag: boolean;
  isSending: boolean;
}

const CustomStepper: React.FC<CustomStepperProps> = ({
  onFormSubmit,
  resetFlag,
  isSending,
}) => {
  const { translations } = useLocalization();
  const [activeStep, setActiveStep] = useState(0);
  const [value, setValue] = useState<number>(50000); // Default value
  const [chakraPhone, setChakraPhone] = useState(translations.lang_code === 'nl' ? "+31" : "+971");

  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    phone: "",
    email: "",
    investmentAmount: 50000,
    referralSource: "",
    referralDetails: "",
    startTiming: "",
  });

  const handlePhoneNumber = (phone: string) => {
    setFormData({ ...formData, phone: phone });
    setChakraPhone(phone);
  };

  useEffect(() => {
    if (resetFlag) {
      resetForm();
    }
  }, [resetFlag]);

  const resetForm = () => {
    setActiveStep(0);
    setFormData({
      fullName: "",
      phone: "",
      email: "",
      investmentAmount: 50000,
      referralSource: "",
      referralDetails: "",
      startTiming: "",
    });
  };

  const steps = [
    { title: "First", description: translations.stepper_contact_details },
    { title: "Second", description: translations.stepper_howmuch_investement },
  ];
  const toast = useToast();

  const activeStepText = steps[activeStep].description;

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleSubmit = () => {
    if (
      !formData.fullName ||
      !formData.phone ||
      !formData.email ||
      !formData.referralSource ||
      !formData.startTiming
    ) {
      toast({
        title: translations.stepper_error_fieldsnotempty,
        description: translations.stepper_error_fieldsnotempty_description,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      onFormSubmit(formData);
    }
  };

  const isFormValid = () => {
    const isEmailValid = (email: string) => {
      // Regular expression pattern for a simple email validation
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      return emailRegex.test(email);
    };

    return (
      formData.fullName !== "" &&
      formData.phone !== "" &&
      isEmailValid(formData.email)
      // Add more fields as needed
    );
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>
                {translations.stepper_fullname}<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                placeholder="John Doe"
                value={formData.fullName}
                onChange={(e) =>
                  setFormData({ ...formData, fullName: e.target.value })
                }
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                {translations.stepper_phone}<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <div style={{ zIndex: 2 }}>
                <ChakraPhone value={chakraPhone} onChange={handlePhoneNumber} />
              </div>
            </FormControl>
            <FormControl>
              <FormLabel>
                {translations.stepper_email}<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                type="email"
                placeholder="name@example.com"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </FormControl>
          </VStack>
        );
      case 1:
        return (
          <VStack spacing={4}>
            <FormControl marginBottom="1rem">
              <FormLabel>
                {translations.stepper_dia}
                <span style={{ color: "red" }}>*</span>
              </FormLabel>

              <Slider
                aria-label="slider-ex-4"
                min={50000} // Set the minimum value to 25,000
                max={1000000} // Set the maximum value to 1,000,000
                defaultValue={50000} // Set the default value to 25,000
                step={5000} // Set the step (increments) to 1,000
                onChange={(value) => {
                  setValue(value);
                  setFormData({ ...formData, investmentAmount: value });
                }} // Use state or context to manage the slider value
              >
                <SliderTrack bg="gray.300">
                  <SliderFilledTrack bg="primary.500" />
                </SliderTrack>
                <Text fontWeight="bold" textAlign="end" marginTop="-2rem">
                  {translations.valute_sign} {value ? value?.toLocaleString("en-US") : "25.000"}
                </Text>
                <SliderThumb boxSize={8} bgColor="primary.500">
                  <Box color="primary.600" as={translations.lang_code === "nl" ? MdEuroSymbol : FaDollarSign} />
                </SliderThumb>
              </Slider>
            </FormControl>
            <FormControl>
              <FormLabel>
                {translations.stepper_wdhfus}
                <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Select
                //placeholder="Via wat ben je bij ons terecht gekomen?"
                value={formData.referralSource}
                onChange={(e) =>
                  setFormData({ ...formData, referralSource: e.target.value })
                }
                required
              >
                <option value=""></option>
                <option value="Google">Google</option>
                <option value="Social Media">Social Media</option>
              </Select>
            </FormControl>
            {formData.referralSource === "Social Media" && (
              <Textarea
                placeholder={translations.stepper_viawho}
                value={formData.referralDetails}
                onChange={(e) =>
                  setFormData({ ...formData, referralDetails: e.target.value })
                }
                required
              />
            )}
            <FormControl>
              <FormLabel>
                {translations.stepper_hwdy}<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Select
                // placeholder="Binnen wat voor termijn wil je starten?"
                value={formData.startTiming}
                onChange={(e) =>
                  setFormData({ ...formData, startTiming: e.target.value })
                }
                required
              >
                <option value=""></option>
                <option value={translations.stepper_hwdy_1}>{translations.stepper_hwdy_1}</option>
                <option value={translations.stepper_hwdy_2}>{translations.stepper_hwdy_2}</option>
                <option value={translations.stepper_hwdy_3}>{translations.stepper_hwdy_3}</option>
                <option value={translations.stepper_hwdy_4}>{translations.stepper_hwdy_4}</option>
              </Select>
            </FormControl>
          </VStack>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <VStack>
        <Stack
          width="100%" // Make it full width
          justifyContent="space-between"
          paddingX={4} // Add horizontal padding for spacing
          paddingBottom={4} // Add bottom padding for spacing
          maxW={{ base: "100%", md: "600px" }} // Adjust the maximum width based on screen size
        >
          <Heading textAlign="center" size="sm">
            {activeStepText}
          </Heading>
          <Stepper size="lg" index={activeStep} colorScheme="facebook">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon color="white" />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                {index !== steps.length - 1 && <StepSeparator />}
              </Step>
            ))}
          </Stepper>
        </Stack>

        <Stack
          width="100%" // Make it full width
          justifyContent="space-between"
          paddingX={4} // Add horizontal padding for spacing
          paddingBottom={4} // Add bottom padding for spacing
          maxW={{ base: "100%", md: "600px" }} // Adjust the maximum width based on screen size
        >
          {renderStep(activeStep)}
        </Stack>
        <HStack spacing={4} mt={4}>
          {activeStep === 0 ? null : (
            <Button
              textAlign={{ base: "center", md: "left" }}
              bgColor="primary.500"
              size="lg"
              marginTop="1rem"
              leftIcon={<ArrowBackIcon />}
              _hover={{ bg: "primary.600", color: "white" }}
              _active={{
                bg: "#dddfe2",
                transform: "scale(0.98)",
                borderColor: "#bec3c9",
              }}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(206, 182, 136, 1), 0 1px 1px rgba(0, 0, 0, .15)",
              }}
              onClick={handleBack}
            >
              {translations.stepper_back}
            </Button>
          )}

          {activeStep < steps.length - 1 ? (
            <Button
              textAlign={{ base: "center", md: "left" }}
              bgColor="primary.600"
              size="lg"
              marginTop="1rem"
              color="white"
              rightIcon={<ArrowForwardIcon />}
              _hover={{ bg: "primary.500", color: "white" }}
              _active={{
                bg: "#dddfe2",
                transform: "scale(0.98)",
                borderColor: "#bec3c9",
              }}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(206, 182, 136, 1), 0 1px 1px rgba(0, 0, 0, .15)",
              }}
              onClick={handleNext}
              isDisabled={!isFormValid()}
            >
              {translations.stepper_next}
            </Button>
          ) : (
            <Button
              textAlign={{ base: "center", md: "left" }}
              bgColor="primary.600"
              size="lg"
              marginTop="1rem"
              color="white"
              rightIcon={<ArrowForwardIcon />}
              _hover={{ bg: "primary.500", color: "white" }}
              _active={{
                bg: "#dddfe2",
                transform: "scale(0.98)",
                borderColor: "#bec3c9",
              }}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(206, 182, 136, 1), 0 1px 1px rgba(0, 0, 0, .15)",
              }}
              onClick={handleSubmit}
              isLoading={isSending}
            >
              {translations.stepper_submit}
            </Button>
          )}
        </HStack>
      </VStack>
    </>
  );
};

export default CustomStepper;
