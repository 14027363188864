import React, { useState, useEffect } from "react";
import {
  Grid,
  GridItem,
  Heading,
  Box,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  HStack,
  Text,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { MdEuroSymbol } from "react-icons/md";
import { numberFormatVal } from "../constants/utils";
import { FaDollarSign } from "react-icons/fa";
import { useLocalization } from "../context/LocalizationContext";

const InvestementCalculator: React.FC = () => {
  const [value, setValue] = useState<number>(150000); // Default value
  const { translations } = useLocalization();

  // Add a state to hold the ROI calculation
  const [roi, setROI] = useState<number>(0);
  //const [jaren, setJaren] = useState<number>(0);
  const [aantalMaanden, setAantalMaanden] = useState<number>(0);
  const [roiPercentage, setROIPercentgae] = useState<number>(0);
  const [maandelijkseUitbetaling, setMaandelijkseUitbetaling] =
    useState<number>(0);

  // Initialize months to a default value

  // Define your investment ranges and corresponding ROI values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const investmentRanges = [
    { min: 50000, max: 100000, roi: 0.20, months: 20 },
    { min: 101000, max: 200000, roi: 0.22, months: 24 },
    { min: 201000, max: 300000, roi: 0.24, months: 30 },
    { min: 301000, max: 500000, roi: 0.27, months: 32 },
    { min: 501000, max: 1000000, roi: 0.30, months: 36 },
    // { min: 150000, max: 250000, roi: 0.26, months: 24 },
    // { min: 251000, max: 500000, roi: 0.29, months: 24 },
    // { min: 501000, max: 810000, roi: 0.32, months: 24 },
    // { min: 811000, max: 1000000, roi: 0.38, months: 24 },
  ];

  useEffect(() => {
    // Find the ROI based on the slider value
    const selectedRange = investmentRanges.find(
      (range) => value >= range.min && value <= range.max
    );

    if (selectedRange) {
      const roiValue: number = selectedRange.roi;
      const months = selectedRange.months;
      const rendement = value * roiValue;
      const roiAmount: number = rendement;
      const mU = (value + roiAmount) / months;
      setROI(roiAmount);
      setAantalMaanden(months);
      setROIPercentgae(roiValue * 100);
      setMaandelijkseUitbetaling(mU);
    }
  }, [value, investmentRanges]);

  return (
    <Grid
      maxWidth="1200px"
      minWidth={{ base: "", md: "1200px" }}
      mx="auto"
      p={{ base: "1rem", md: "1rem" }}
      templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
      gap={5}
      marginBottom="2rem"
    >
      <GridItem
        border="2px"
        borderColor="primary.500"
        borderRadius="5px"
        padding="30px"
      >
        <Heading size="md" color="primary.600">
          {translations.investment_calculator_subtitle}
        </Heading>
        <br />
        <HStack justifyContent="space-between" marginBottom="1rem">
          <Text>{translations.investment_calculator_desire}</Text>
          <Text fontWeight="bold">
            {translations.valute_sign} {value ? value?.toLocaleString("nl-NL") : "25.000"}
          </Text>
        </HStack>
        <Slider
          aria-label="slider-ex-4"
          min={50000} // Set the minimum value to 25,000
          max={1000000} // Set the maximum value to 1,000,000
          defaultValue={150000} // Set the default value to 25,000
          step={5000} // Set the step (increments) to 1,000
          onChange={(value) => setValue(value)} // Use state or context to manage the slider value
        >
          <SliderTrack bg="gray.300">
            <SliderFilledTrack bg="primary.500" />
          </SliderTrack>
          <SliderThumb boxSize={8} bgColor="primary.500">
            {translations.valute_sign === "€" ? <Box color="primary.600" as={MdEuroSymbol} /> : <Box color="primary.600" as={FaDollarSign} />}
          </SliderThumb>
        </Slider>
        <br />
        <HStack justifyContent="space-between" margin="0.5rem">
          <Text fontSize={{ base: "xs", md: "16px" }}>{translations.investment_calculator_total}</Text>
          <Text fontWeight="bold" fontSize={{ base: "xs", md: "16px" }}>
            {translations.valute_sign} {value ? value?.toLocaleString("nl-NL") : "0"}
          </Text>
        </HStack>
        <Divider />
        <HStack justifyContent="space-between" margin="0.5rem">
          <Text fontSize={{ base: "xs", md: "16px" }}>{translations.investment_calculator_return}</Text>
          <Text fontWeight="bold" fontSize={{ base: "xs", md: "16px" }}>
            {translations.valute_sign} {roi ? roi?.toLocaleString("nl-NL") : "0"} (
            {roiPercentage.toFixed()}%)
          </Text>
        </HStack>
        <Divider />
        <HStack justifyContent="space-between" margin="0.5rem">
          <Text fontSize={{ base: "xs", md: "16px" }}>
            {translations.investment_calculator_earnback}
          </Text>
          <Text fontWeight="bold" fontSize={{ base: "xs", md: "16px" }}>
            {translations.valute_sign} {value ? (value + roi)?.toLocaleString("nl-NL") : "0"}
          </Text>
        </HStack>
      </GridItem>

      <GridItem alignItems="center" justifyContent="center">
        <Text fontSize="2xl" color="primary.600" textAlign="center">
          {translations.investment_calculator_expected_text}
          <br /> <b>{translations.valute_sign} {roi.toLocaleString("nl-NL")}</b> {translations.in}{" "}
          <b>{aantalMaanden ? aantalMaanden : 0} {translations.months}</b>
        </Text>
        <br />
        <Text
          fontSize="2xl"
          color="primary.600"
          textAlign="center"
          fontWeight="bold"
        >
          {translations.investment_calculator_monthlypayout}
        </Text>
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="1rem"
        >
          <Box bg="primary.500" p={4} color="white" borderRadius="10px">
            <Text
              fontSize="4xl"
              color="primary.600"
              textAlign="center"
              fontWeight="bold"
            >
              <b>{numberFormatVal(maandelijkseUitbetaling, translations.valute_sign === "€" ? "EUR" : "USD", translations.valute_sign === "€" ? "nl-NL" : "en-US")}</b>
            </Text>
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default InvestementCalculator;
