import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS for proper styling
import L from "leaflet"; // Import Leaflet to create custom icon
import mapMarkerIcon from "../assets/map-marker.png";

const Map: React.FC = () => {
  const mapStyles: React.CSSProperties = {
    height: "400px", // Adjust the height as needed
    width: "100%",
    borderRadius: "10px", // Use 100% width to fill the container
  };

  const customTileLayer = {
    url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", // Gray map tiles
    attribution: '&copy; <a href="https://carto.com/">CARTO</a>',
    subdomains: "abcd",
    maxZoom: 19,
    minZoom: 1,
  };

  const customIcon = new L.Icon({
    iconUrl: mapMarkerIcon, // Replace with the path to your marker image
    iconSize: [32, 32], // Adjust the size of the marker image
    iconAnchor: [16, 32], // Adjust the anchor point if needed
  });

  return (
    <MapContainer
      center={[25.18419415655304, 55.26385061469498]}
      zoom={13}
      style={mapStyles}
    >
      <TileLayer {...customTileLayer} />
      <Marker
        position={[25.18419415655304, 55.26385061469498]}
        icon={customIcon}
      >
        <Popup>
          <strong>Alliance Capital</strong>
          <br />
          <small>
            The Bayswater Tower 507
            
            <br />
            Dubai - UAE
          </small>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
