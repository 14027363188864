import {
  Box,
  Card,
  Flex,
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import HeroTitle from "./components/HeroTitle";
import PropertyCard from "./components/PropertyCard";
import image1 from "./assets/properties/1.webp";
import image2 from "./assets/properties/2.webp";
import image3 from "./assets/properties/3.webp";
import image4 from "./assets/properties/4.webp";
import image5 from "./assets/properties/5.webp";
import image6 from "./assets/properties/6.webp";
import image7 from "./assets/properties/7.webp";
import image8 from "./assets/properties/8.webp";
import image9 from "./assets/properties/9.webp";
import image10 from "./assets/properties/10.webp";
import image11 from "./assets/properties/11.webp";
import image12 from "./assets/properties/12.webp";
import image13 from "./assets/properties/13.webp";
import image14 from "./assets/properties/14.webp";
import Footer from "./components/Footer";
import TabbedImageSlider from "./components/TabbedImageSlider";
import ReactGA from "react-ga4";

const Fade = require("react-reveal/Fade");

ReactGA.send({
  hitType: "pageview",
  page: "/invest",
  title: "Properties | Alliance Capital",
});

export const Investeren = () => {
  document.title = "Properties | Alliance Capital";

  return (
    <>
      <Fade>
        <Box marginTop="2rem"></Box>
        <HeroTitle
          title="Invest in Dubai"
          subtitle="Are you ready to invest in Dubai? With Alliance Capital you get maximum return on your investment regardless of what the market does."
        />

        <Box
          paddingTop="2rem"
          paddingBottom="2rem"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Tabs variant="soft-rounded" colorScheme="green" align="center">
            <TabList>
              <Card flexDir="row" p="0.5rem" borderRadius="20px">
                <Tab>Available</Tab>
                <Tab color="primary.600">Fully Rented</Tab>
              </Card>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Grid
                  maxWidth="1200px"
                  mx="auto"
                  p={{ base: "0rem", md: "2rem" }}
                  paddingTop={{ base: "1rem", md: "2rem" }} // Adjust padding for different screen sizes
                  templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} // Three equal-width columns on medium and larger screens
                  gap={5} // Gap between grid items
                >
                  <GridItem>
                    <PropertyCard
                      propertyName="MAG318"
                      img={image1}
                      investering={25000}
                      rendement={16}
                      uitbetaling={2416}
                      location="Business Bay, Dubai"
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Boulevard Point"
                      location="Downtown, Dubai"
                      img={image2}
                      investering={50000}
                      rendement={19}
                      uitbetaling={4958}
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Burj Royale"
                      img={image3}
                      location="Downtown, Dubai"
                      investering={50000}
                      rendement={19}
                      uitbetaling={4958}
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Five Palm"
                      img={image4}
                      investering={100000}
                      rendement={22}
                      uitbetaling={10166}
                      location="Palm Jumeirah, Dubai"
                    />
                  </GridItem>

                  <GridItem>
                    <PropertyCard
                      propertyName="Address Dubai Mall"
                      img={image8}
                      location="Downtown, Dubai"
                      investering={40000}
                      rendement={19}
                      uitbetaling={3966}
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Beach Vista"
                      img={image9}
                      location="Beachfront, Dubai"
                      investering={80000}
                      rendement={21}
                      uitbetaling={8066}
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Sunrise Bay"
                      img={image10}
                      location="Beachfront, Dubai"
                      investering={70000}
                      rendement={19}
                      uitbetaling={6941}
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Damac Vera / Reva"
                      img={image11}
                      location="Business Bay, Dubai"
                      investering={37000}
                      rendement={14}
                      uitbetaling={3515}
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="St. Regis Palm Tower"
                      img={image12}
                      location="Palm Jumeirah, Dubai"
                      investering={80000}
                      rendement={20}
                      uitbetaling={8000}
                    />
                  </GridItem>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid
                  maxWidth="1200px"
                  mx="auto"
                  p={{ base: "0rem", md: "2rem" }}
                  paddingTop={{ base: "1rem", md: "2rem" }}
                  templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} // Three equal-width columns on medium and larger screens
                  gap={5} // Gap between grid items
                >
                  <GridItem>
                    <PropertyCard
                      propertyName="Dubai Hills"
                      location="Dubai Hills, Dubai"
                      img={image5}
                      investering={75000}
                      rendement={17}
                      uitbetaling={7312}
                      blurText
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Souk Al Bahar"
                      img={image6}
                      location="Downtown, Dubai"
                      investering={65000}
                      rendement={14}
                      uitbetaling={6175}
                      blurText
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Signature Livings"
                      img={image7}
                      location="JVC, Dubai"
                      investering={170000}
                      rendement={15}
                      uitbetaling={1627}
                      blurText
                    />
                  </GridItem>

                  <GridItem>
                    <PropertyCard
                      propertyName="Bloom Towers"
                      img={image13}
                      location="JVC, Dubai"
                      investering={20000}
                      rendement={20}
                      uitbetaling={2000}
                      blurText
                    />
                  </GridItem>
                  <GridItem>
                    <PropertyCard
                      propertyName="Paramount Dubai"
                      img={image14}
                      location="Design District, Dubai"
                      investering={75000}
                      rendement={21}
                      uitbetaling={7562}
                      blurText
                    />
                  </GridItem>
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <Footer />
      </Fade>
    </>
  );
};
