import { useState, useEffect } from "react";
import { Text, Card, Grid, GridItem, Heading } from "@chakra-ui/react";
import { useLocalization } from "../context/LocalizationContext";

const AchievmentNumbers = () => {
  const [portfolioValue, setPortfolioValue] = useState(0);
  const [payoutsPerYear, setPayoutsPerYear] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const { translations } = useLocalization();


  const finalValues = {
    portfolioValue: 76,
    payoutsPerYear: 4,
    propertiesCount: 150,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPortfolioValue((prev) => (prev < finalValues.portfolioValue ? prev + 1 : finalValues.portfolioValue));
      setPayoutsPerYear((prev) => (prev < finalValues.payoutsPerYear ? prev + 1 : finalValues.payoutsPerYear));
      setPropertiesCount((prev) => (prev < finalValues.propertiesCount ? prev + 1 : finalValues.propertiesCount));
    }, 50); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid
      maxWidth="1200px"
      mx="auto"
      p={{ base: "1rem", md: "2rem" }}
      templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
      gap={5}
      marginTop="-5rem"
    >
      <GridItem>
        <Card p="1rem" marginTop={{ base: "1rem", md: "0px" }}>
          <Heading color="primary.600">{translations.valute_sign} {portfolioValue} mln +</Heading>
          <Text>{translations.targets_portfolio}</Text>
        </Card>
      </GridItem>
      <GridItem>
        <Card p="1rem" marginTop={{ base: "1rem", md: "0px" }}>
          <Heading color="primary.600">{translations.valute_sign} {payoutsPerYear} mln +</Heading>
          <Text>{translations.targets_payouts}</Text>
        </Card>
      </GridItem>
      <GridItem>
        <Card p="1rem" marginTop={{ base: "1rem", md: "0px" }}>
          <Heading color="primary.600">{propertiesCount} +</Heading>
          <Text>{translations.targets_properties}</Text>
        </Card>
      </GridItem>
    </Grid>
  );
};

export default AchievmentNumbers;
