const t = {
  lang_code: "en",
  valute_sign: "$",
  month: "month",
  months: "months",
  in: "in",
  what_our_investors_say: "What our investors say",
  what_our_investorts_say_button: "Learn more testimonials",
  disclaimer_investment:
    "Under the supervision and in collaboration with DIFC (Dubai International Financial Center)",

  // CALL TO ACTION
  call_to_action_title: "Ready for a financially stable future?",
  call_to_action_subtitle: "Start your investment today!",
  call_to_action_button: "Start investing now",

  // ##Header
  header_menu_home: "Home",
  header_menu_aboutus: "About us",
  header_menu_goldenvisa: "Golden Visa",
  header_menu_contact: "Contact",
  header_menu_bookmeeting: "Book a meeting",

  // #Home Page
  home_hero_title: "24% yield in 30 months",
  home_hero_subtitle:
    "No unnecessary fuss. Contracts of 20, 24, 30, 32, or 36 months, fixed monthly payout, and a guaranteed return regardless of what the market does.",
  home_hero_bulletpoints: {
    1: "100% Capital Protection & Security",
    2: "No Fees or Hidden Charges",
    3: "Monthly Payouts",
  },
  home_hero_meeting: "Schedule a meeting",
  home_call_title:
    "We deliver meticulously managed, high-yield investment solutions designed to suit diverse investor preferences.",
  home_call_subtitle:
    "Alliance Capital ensures a stress-free investment experience, offering meticulously managed, high-yield solutions tailored to diverse investor preferences. Our dedicated team at Alliance Capital takes care of everything, providing clients with the comfort of knowing that all concerns are expertly handled, allowing them to relax and enjoy optimized returns throughout their investment journey.",
  home_values_bulletpoints: {
    1: "High Fixed Returns",
    2: "Profit Sharing Model",
    3: "Our Guarantee",
  },
  home_values_bulletpoints_txt: {
    1: "Build your wealth with a personalised diversified portfolio with expert advice, fully handled by us.",
    2: "Operating under a model of profit sharing, we make money when our investors make money and our success depends on your success.",
    3: "Alliance Capital Guarantees Your Prosperous Future. Rely on our expertise for assured growth and financial security.",
  },
  // #Targets
  targets_portfolio: "Portfolio Value",
  targets_payouts: "Payouts per Year",
  targets_properties: "Properties",

  // # Investment calculator
  investment_calculator: "Investment calculator",
  investment_calculator_subtitle: "Easily calculate your return.",
  investment_calculator_desire: "Desired investment",
  investment_calculator_total: "Total investment",
  investment_calculator_return: "Total return",
  investment_calculator_earnback: "Totally earned back",
  investment_calculator_expected_text: "Expected return on investment of",
  investment_calculator_monthlypayout: "Monthly Payout",

  rewards_title: "Receive benefits while investing.",
  rewards_subtitle:
    "As you invest more, your benefits increase! All investors automatically become members of our membership club.",
  rewards_investementof: "With an investment of",
  rewards_youget: "you receive",
  rewards_amount: "Your investment amount",

  startup: {
    cashback: "No cashback on your next investment",
    refferal: "$500 per referral",
    welcome: "No welcome package",
    holiday: "No stay in Dubai",
    flight: "No First class round-trip ticket",
  },
  elite: {
    cashback: "5% cashback on your next investment",
    refferal: "$750 per referral",
    welcome: "Welcome package worth $500",
    holiday: "No stay in Dubai",
    flight: "No First class round-trip ticket",
  },
  vip: {
    cashback: "7% cashback on your next investment",
    refferal: "$1250 per referral",
    welcome: "Welcome package worth $1000",
    holiday: "7 days stay in Dubai",
    flight: "No First class round-trip ticket",
  },
  platinum: {
    cashback: "10% cashback on your next investment",
    refferal: "$2000 per referral",
    welcome: "Welcome package worth $2000",
    holiday: "14 days stay in Dubai",
    flight: "First class round-trip ticket",
  },

  realestate_unites_title:
    "Real estate unites the strengths of all types investments",
  realestate_unites_text:
    "It is among the most crucial categories of assets to and to build long-term wealth.",

  capitalGrowth: {
    heading: "Capital growth",
    text: "Outside of generating monthly passive income, real estate grows in value, just like stocks, and this contributes to building long-term wealth investors.",
  },
  passiveIncome: {
    heading: "Passive income",
    text: "Real estate provides a reliable passive income stream, just like bonds, in the form of regular monthly rental income.",
  },
  protectionAgainstInflation: {
    heading: "Protection against inflation",
    text: "Real estate is the oldest asset class in history and has always functioned as an excellent protection during inflationary periods, just like gold.",
  },
  storageOfWealth: {
    heading: "Storage of wealth",
    text: "Everyone dreams of a financially stable future. With real estate from Alliance Capital, you are assured of this bright future.",
  },

  // About
  grow_with_alliance: `Grow with Alliance Capital`,
  about_ready:
    "Ready to strengthen your financial future and make your dreams come true?",
  about_alliance: "About Alliance Capital",
  about_alliance_text: "Your investment adventure in Dubai starts here!",
  about_us: "About us",
  contact_us: "Contact us",
  about_us_text:
    'Our name, "Alliance Capital," reflects our commitment to working together with our investors. We believe in the power of partnership and share our profits with you. Your success is our success, and that is the core of who we are.',
  about_us_text2:
    "At Alliance Capital we also understand that trust is the foundation of every successful investment. That's why we go further than just those words; we offer transparency and integrity in all our transactions. We strive to shape the future of your finances protect and grow so you can focus on what is really important in life.",
  about_us_text3:
    "Our determination to innovate and think ahead in the financial world is what sets us apart. At Alliance Capital we are ready to guide you on your financial journey security. So what are you waiting for? Let's work together, invest and secure the future of your finances.",
  about_us_text4:
    "Join the Alliance Capital family and discover the power of investing with confidence. Together we build a solid financial future.",
  about_ready_text1:
    "Imagine, a place where your financial dreams can come true, where investing becomes an art and profit is a promise. Welcome to Alliance Capital, where we take investing to a whole new level.",
  about_ready_text2:
    "At Alliance Capital, we understand that the world of finance often... can be unpredictable and full of risks. That's why we have an oasis of certainty created in the form of our unique investment opportunities. With our permanent contract of 16, 18 or 24 months we offer not only stability, but also a fixed percentage return on your investment. It is as a journey on a steady course towards financial growth.",

  // GOLDEN VISA
  golden_visa_heading_text:
    "Alliance Capital makes obtaining your Golden Visa simpler.",
  golden_visa_heading_subtitle:
    "Invest from anywhere in the world and insure yourself of your UAE Golden Visa.",
  golden_visa_whatis: "What is a Golden Visa?",
  golden_visa_whatis_text:
    "A Golden Visa from the United Arab Emirates (UAE) is a long-term residency program that offers several benefits, and we make it available to anyone who has at least invest AED 2,000,000 (approximately $515,000) via our platform.",
  golden_visa_smartways_title:
    "The smartest way to get your Golden Visa available",

  longTermResidenceRights: {
    heading: "Obtain long-term residence rights",
    text: "The Golden Visa offers a 10-year, renewable residence permit. This means that you can stay in the United Arab Emirates for a longer period of time, live and work without having to worry about renewing your visa.",
  },
  bringWholeFamily: {
    heading: "Bring your whole family",
    text: "As a Golden Visa holder, you can bring your entire family as sponsors, including your spouse and children, regardless of their age. The whole family has the right to access top-notch healthcare, education, and employment opportunities in the United Arab Emirates.",
  },
  travelWithoutRestrictions: {
    heading: "Travel without restrictions",
    text: "Work from anywhere, travel easily, and return to the United Arab Emirates without worrying about your residency status.",
  },
  enterFastestGrowingMarket: {
    heading: "Enter the world's fastest-growing market",
    text: "The Golden Visa offers you the chance to prosper in the business environment of the United Arab Emirates, including free zones, tax benefits, and access to top talent.",
  },

  accessibility: {
    heading: "Accessibility",
    text: "Invest in Dubai's lucrative real estate market from any place in the world. With this, you can enjoy your long-lasting residence permit in the United Arab Emirates and diversify your investment portfolio at the same time.",
  },
  diversification: {
    heading: "Diversification",
    text: "Spread your capital across several first-class real estate properties in Dubai to create a diversified real estate portfolio with reduced risk and greater potential return.",
  },
  investWithoutWorries: {
    heading: "Invest without worries",
    text: "Experience a seamless, mobile-centric digital platform that saves time and money by eliminating paperwork and manages your real estate investments from start to finish.",
  },
  bestInvestmentDeals: {
    heading: "The best investment deals",
    text: "Generate rental income faster and ensure a stable income stream with real estate that can be directly occupied, eliminating the need for investments in off-plan projects.",
  },

  platinumMembershipHeading: "Unlock our Platinum membership",
  platinumMembershipText:
    "The Capital Platinum membership represents our most valuable customers and comes with a range of exceptional benefits and tailor-made services.",
  referral: {
    heading: "$ 2000",
    text: "per referral",
  },
  cashback: {
    heading: "10% cashback",
    text: "on your next investment",
  },
  welcomePack: {
    heading: "Welcome pack",
    text: "worth $2000",
  },
  stayInDXB: {
    heading: "Stay in DXB",
    text: "14 days, incl. first class ticket",
  },

  founderName: "Milad Ma",
  founderTitle: "Founder & CEO",
  founderQuote:
    "As the only platform that makes it possible for Dutch people to invest in Dubai with a fixed payout and fixed return per year, we now also have a partnership with DIFC to provide people who invest higher with a Golden Visa. This is a golden opportunity for anyone wanting a piece of Dubai. This collaboration is proof that we as an investment platform are working day and night to achieve a win-win-win situation.",

  // FAQ

  faqHeading: "FAQ",
  faqDescription:
    "Here are some common questions regarding obtaining your Golden Visa through Alliance Capital! Do not hesitate to contact us if you have any questions; our team stands ready for you!",

  faqItems: [
    {
      title: "What is a Golden Visa?",
      content:
        "The United Arab Emirates' Golden Visa offers a 10-year, renewable residence permit and a range of benefits, such as family sponsorship, access to first-class healthcare and education services, business and employment opportunities, and the flexibility to travel without worrying about your residency status.",
    },
    {
      title:
        "Do I have to be an existing resident of Dubai to be eligible for the Golden Visa with Alliance Capital?",
      content:
        "No, you don't need to be a resident of Dubai to be eligible. Any global investor who meets the requirements for professional investors is eligible to invest with Alliance Capital for the Golden Visa.",
    },
    {
      title:
        "What is the minimum investment amount required to be eligible for a Golden Visa?",
      content:
        "The required minimum investment amount is AED 2,000,000 ($ 515,000).",
    },
    {
      title: "Can I sell my property and still retain my Golden Visa?",
      content:
        "Yes, you can sell your property and retain your golden visa, as long as you reinvest the proceeds into another qualifying investment in the UAE.",
    },
    {
      title:
        "Are there additional costs or charges for applying for the Golden Visa with Alliance Capital?",
      content:
        "Yes, there are service fees totaling AED 6,100.00 when applying for the Golden Visa. Please note that this amount is exclusive of the Value Added Tax (VAT) amount of AED 76.83. These fees cover various administrative and processing costs associated with the application.",
    },
    {
      title: "Can I use the Golden Visa to work in the UAE?",
      content:
        "Yes, you can use the golden visa to work in the UAE, but you must obtain the required work permits and meet the requirements set by the UAE government.",
    },
    {
      title: "Can I leave the UAE while having the Golden Visa?",
      content:
        "Yes, you can leave the UAE whenever you want while having the Golden Visa.",
    },
    {
      title: "How long does it take to get the Golden Visa?",
      content:
        "The estimated processing time to obtain the visa is a minimum of 2 weeks. Please note that this time period does not account for unexpected delays that may occur due to external factors or decisions by the relevant authorities.",
    },
    {
      title: "Is the Golden Visa guaranteed if I invest?",
      content:
        "While investing can significantly improve your chances of obtaining a visa, it is important to note that there is no guarantee of visa approval. The final decision rests with the relevant authorities, who will assess your application based on various criteria, including your investment, background, and compliance with specific program requirements.",
    },
  ],
  // Contact
  contact_title: "We will personally guide you with your investment in Dubai!",
  contactform_title:
    "Contact us via the contact form below. We answer your question as soon as possible!",
  contactform_address: "Address",
  contactform_contactdetails: "Contact details",
  contactform_fullname: "Fullname",
  contactform_email: "Email",
  contactform_message: "Message",
  contactform_send: "Send",
  contact_us_hero_text: "Contact us",

  // Stepper
  stepper_contact_details: "Contact Details",
  stepper_howmuch_investement: "How much do you want to invest?",
  stepper_error_fieldsnotempty: "Fields cannot be empty",
  stepper_error_fieldsnotempty_description:
    "You have left fields that cannot be empty.",
  stepper_fullname: "First and Last Name",
  stepper_phone: "Phone Number",
  stepper_email: "Email Address",
  stepper_dia: "Desired Investment Amount?",
  stepper_wdhfus: "Where did you hear about us?",
  stepper_viawho: "Via @whom (if applicable)",
  stepper_hwdy: "How quickly do you want to start?",
  stepper_hwdy_1: "Within 24 hours",
  stepper_hwdy_2: "Within 3 days",
  stepper_hwdy_3: "Within a week",
  stepper_hwdy_4: "Later",
  stepper_back: "Back",
  stepper_next: "Next",
  stepper_submit: "Submit",
  stepper_index_text: "Leave your details and we will contact you.",

  compare_title: "Compare Fixed rate Investment Groups",
  compare_text:
    "Our proven track record allows us to offer investors fixed returns via Alliance Capital, while assuming all of the risk ourselves helping you invest in a safe way.",
  none: "None",
  annual_equivalent_rate: "Annual Equivalent Rate",
  fyr: "Fixed Yearly Returns",
  fr: "Fixed Returns",
  vyr: "Variable Yearly Returns",
  month_bond: "Month Bond",
  month_fixed: "Month Fixed Rate",
  term: "Term",
  deposit: "Deposit",
  roi: "ROI",

  // WHY
  why_alliance: "Why Alliance Capital",
  why_alliance_text: "",
  why_tab_1: "Assured Returns",
  why_tab_2: "Monthly Profit Distribution",
  why_tab_3: "Secure Investments",
  why_tab_4: "Balanced High Returns",
  why_tab_5: "Transparent Operations",
  why_tab_1_text:
    "Count on Alliance Capital for consistent and secure returns, offering investors peace of mind.",
  why_tab_2_text:
    "Experience dependable monthly payouts, ensuring a steady income stream to meet your financial goals.",
  why_tab_3_text:
    "With our robust risk management and diverse portfolio strategy, we prioritize the safety of your investments.",
  why_tab_4_text:
    "Alliance Capital aims for attractive returns while maintaining a careful balance with security.",
  why_tab_5_text:
    "We uphold transparency through clear communication and comprehensive reporting, keeping you well-informed about your investments.",

  //
  free_residency: "Free Residency",
  no_free_residency: "No Free Residency",
  no: "No",
};

export default t;
